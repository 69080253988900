import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from '@tinymce/tinymce-react';
import { success } from "toastr";

class SeoConfig extends Component {
    constructor(props){
        super(props)
        this.state = {
            basic: false,
            authorSEO: '',
            titleSEO: '',
            descSEO: '',
            urlSEO: '',
            firstUrlSEO: '',
            tempFirstSEO: '',
            keywordsSEO: '',
            ogTitle: '',
            ogDesc: '',
            ogImage: '',
            ogType: '',
            twitterTitle: '',
            twitterCreator: '',
            twitterDesc: '',
            content_id: null,
            content_type: '',
            process: false,
            content_title:'',
        }
    }


    componentDidMount(){
        this.getDetailSEOConfig();
    }
    

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    goBack(){
        this.props.history.goBack();
    }

    getDetailSEOConfig = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "content_id": routerSplitter[4]
        }
        // console.log("isi", body)
        httprequester.postnobody('admin/getdetail_seo', body, {
            success: async function (response) {
                // console.log("res => ", response.data.settings.schema)
                // console.log("res1 =>", response.data.settings.datarows[0][0])
                if (response.data.message == "success") {
                        // console.log(response.data.settings.schema[0]['name'])
                        // console.log("isi", response.data.result)
                        if (response.data.result.length != 0 ) {
                        //    console.log(response.data.message)
                            let router = window.location.href
                            let routerSplitter = router.split("/");
                            this.setState({
                                content_title: "Seo Config : " + response.data.result[0].title,
                                content_title2: "SEO /" + " " + "Seo Config : " + routerSplitter[5],
                                twitterDesc:response.data.result[0].twitter_description,
                                keywordsSEO:response.data.result[0].keywords,
                                ogTitle:response.data.result[0].og_title,
                                authorSEO:response.data.result[0].author,
                                descSEO:response.data.result[0].description,
                                ogType:response.data.result[0].og_type,
                                titleSEO:response.data.result[0].title,
                                urlSEO:response.data.result[0].url,
                                firstUrlSEO: response.data.result[0].first_url,
                                tempFirstSEO: response.data.result[0].url,
                                ogImage:response.data.result[0].og_image,
                                twitterCreator:response.data.result[0].twitter_creator,
                                ogDesc:response.data.result[0].og_description,
                                twitterTitle:response.data.result[0].twitter_title,
                            })
                            
                        } else {
                            this.setState({
                                twitterDesc:'',
                                keywordsSEO: '',
                                ogTitle:'',
                                authorSEO:'',
                                descSEO:'',
                                ogType:'',
                                titleSEO:'',
                                urlSEO:'',
                                ogImage:'',
                                twitterCreator:'',
                                ogDesc:'',
                                twitterTitle:''
                            })
                        }
                            
                        
                    }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }
    
    directSEO = () => {
        let url = localStorage.getItem('url')
        let hasil = url.split('/')
        // console.log('hasil',hasil);
        this.props.history.push(`/${hasil[3]}/${hasil[4]}/${hasil[5]}/${hasil[6]}/${hasil[7]}`)
    }

    saveSEOConfig = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "content_id": routerSplitter[4],
            "content_type": routerSplitter[5],
            "author": this.state.authorSEO,
            "title": this.state.titleSEO,
            "description": this.state.descSEO,
            "url": this.state.urlSEO,
            "keywords": this.state.keywordsSEO,
            "og_title": this.state.ogTitle,
            "og_description": this.state.ogDesc,
            "og_image": this.state.ogImage,
            "og_type": this.state.ogType,
            "twitter_title": this.state.twitterTitle,
            "twitter_creator": this.state.twitterCreator,
            "twitter_description": this.state.twitterDesc
        }

        if (this.state.firstUrlSEO === null) {
          body.first_url = this.state.tempFirstSEO;
        }

        // console.log("body config =>", body)
        this.setState({
            process: true
        })
        httprequester.postnobody('admin/seo', body, {
            success: async function (response) {
                // console.log("res => ", response.data.message)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        process: false
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }
   
    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>SEO Config</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <p style={{textTransform: 'upperCase', fontWeight: '600', fontSize: '16px', width: '800px' }}>{`${this.state.content_title}`}</p>
                            </Col>
                            <Col>
                                <p style={{textAlign: 'right', height: '-100px' }}>{`${this.state.content_title2}`}</p>
                            </Col>
                        </Row>
                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="SEO Config berhasil di simpan"
                                onConfirm={() => this.goBack()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.process ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}
            
                        <Row>
                            <Col lg={12}>
                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO() }}> Kembali</button>
                                <br />
                                <br />
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <Row>                                              
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Author SEO</Label>
                                                        <Input
                                                            id="authorSEO"
                                                            type="input"
                                                            placeholder="Input Author SEO"
                                                            onChange={(e) => this.setState({ authorSEO: e.target.value })}
                                                            value={this.state.authorSEO} 
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-descriptionSEO">Description</Label>
                                                        <Input type="textarea"
                                                        placeholder="Input Description"  
                                                        onChange={(e) => this.setState({ descSEO: e.target.value })} value={this.state.descSEO}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Title SEO</Label>
                                                        <Input
                                                            id="titleSEO"
                                                            type="input"
                                                            placeholder="Input Title SEO"
                                                            onChange={(e) => this.setState({ titleSEO: e.target.value })}
                                                            value={this.state.titleSEO}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">URL SEO</Label>
                                                        <Input
                                                            id="urlSEO"
                                                            type="input"
                                                            placeholder="Input URL SEO"
                                                            onChange={(e) => this.setState({ urlSEO: e.target.value })}
                                                            value={this.state.urlSEO}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-keywordsSEO">Keywords</Label>
                                                        <Input type="textarea"
                                                        placeholder="Keywords"  
                                                        onChange={(e) => this.setState({ keywordsSEO: e.target.value })} value={this.state.keywordsSEO}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">OG Title</Label>
                                                        <Input
                                                            id="ogTitle"
                                                            type="input"
                                                            placeholder="Input OG Title"
                                                            onChange={(e) => this.setState({ ogTitle: e.target.value })}
                                                            value={this.state.ogTitle}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-ogDesc">OG Description</Label>
                                                        <Input type="textarea"
                                                        placeholder="Input OG Description" 
                                                        onChange={(e) => this.setState({ ogDesc: e.target.value })} value={this.state.ogDesc} 
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-Input">OG Image</Label>
                                                        <Input
                                                            id="ogImage"
                                                            type="input"
                                                            placeholder="Input OG Image"
                                                            onChange={(e) => this.setState({ ogImage: e.target.value })}
                                                            value={this.state.ogImage}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-Input">OG Type</Label>
                                                        <Input
                                                            id="ogType"
                                                            type="select"
                                                            placeholder="Input OG Type"
                                                            onChange={(e) => this.setState({ ogType: e.target.value })}
                                                            value={this.state.ogType}
                                                        >
                                                        <option value="Video">Video</option>
                                                        <option value="Website">Website</option>
                                                        </Input>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Twitter Title</Label>
                                                        <Input
                                                            id="twitterTitle"
                                                            type="input"
                                                            placeholder="Input Twitter Title"
                                                            onChange={(e) => this.setState({ twitterTitle: e.target.value })}
                                                            value={this.state.twitterTitle}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-Input">Twitter Creator</Label>
                                                        <Input
                                                            id="twitterCreator"
                                                            type="input"
                                                            placeholder="Input Twitter Creator"
                                                            onChange={(e) => this.setState({ twitterCreator: e.target.value })}
                                                            value={this.state.twitterCreator}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-twitterDesc">Twitter Description</Label>
                                                        <Input type="textarea"
                                                        placeholder="Input Twitter Description"  
                                                        onChange={(e) => this.setState({ twitterDesc: e.target.value })}  value={this.state.twitterDesc}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" 
                                    onClick={() => this.saveSEOConfig()}
                                    >
                                        Submit SEO Config
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

}

export default SeoConfig;