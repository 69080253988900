import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Alert
} from 'reactstrap';
import { Link, withRouter } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import PropTypes from 'prop-types';
import { isEmpty, map, size } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Typeahead } from 'react-bootstrap-typeahead';
import { GetIndexFromSchema } from '../../helpers/CommonHelper';

class TindakanMedis extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            totalPage: [],
            listTindakanMedis: [],
            listCategory: [],
            listValue: [],
            alert: false,
            openmodal: false,
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            categoriesTindakanMedis: '',
            statusTindakanMedis: 'Published',
            searchTindakanMedis: '',
            valueTindakanMedis: '',
            urutkanTindakanMedis: '',
            namaalert:'',
            alertcopy:false,
            listSpesialisasi:[]
        }
    }

    componentDidMount() {
        this.getTindakanMedis();
        this.getCategory();
        this.getLov()
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    getLov() {
        let body = {
            hp_type: "tindakan"
        }

        httprequester.get('tindakan/lov/specialization', {
            success: async function (response) {

                let { data } = response;
                // let { detailMessage } = response.data;
                this.setState({
                    // listMolekul: data.resultMolekul,
                    // listPengarang: data.resultPenulis,
                    // listPenyakit: data.resultPenyakit,
                    listSpesialisasi: data.data,
                })
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    directSEO = (id, type) => {
        this.props.history.push(`/seoconfig/${id}/${type}`)
    }

    handlePageClick = (e) => {
        let a = e
        this.setState({
            page:a
        })

        let body = {
            "spesialisasi": "",
            "search": this.state.searchTindakanMedis,
            "penyakit": "",
            "status": this.state.statusTindakanMedis,
            "molekul": "",
            "penulis": "",
            "page": e,
            "limit": 10,
            "urutan": "created_at DESC"
        }

        // console.log("body =>", body)
        httprequester.postarticle('tindakan/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            tindakan_penulis: v[GetIndexFromSchema('tindakan_penulis', response.data.result.data.schema)],
                            tindakan_id: v[GetIndexFromSchema('tindakan_id', response.data.result.data.schema)],
                            tindakan_spesialis: v[GetIndexFromSchema('tindakan_spesialis', response.data.result.data.schema)],
                            tindakan_content: v[GetIndexFromSchema('tindakan_content', response.data.result.data.schema)],
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            tindakan_penyakit: v[GetIndexFromSchema('tindakan_penyakit', response.data.result.data.schema)],
                            tindakan_status: v[GetIndexFromSchema('tindakan_status', response.data.result.data.schema)],
                            tindakan_thumbnail: v[GetIndexFromSchema('tindakan_thumbnail', response.data.result.data.schema)],
                            tindakan_slug: v[GetIndexFromSchema('tindakan_slug', response.data.result.data.schema)],
                            tindakan_molekul: v[GetIndexFromSchema('tindakan_molekul', response.data.result.data.schema)],
                            tindakan_video: v[GetIndexFromSchema('tindakan_video', response.data.result.data.schema)],
                            tindakan_title: v[GetIndexFromSchema('tindakan_title', response.data.result.data.schema)],
                        });
                    })
                    this.setState({
                        listTindakanMedis: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("listTindakanMedis => ", this.state.listTindakanMedis)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getTindakanMedis = () => {

        let body = {
            "spesialisasi": this.state.hasilSpesialisasi,
            "search": this.state.searchTindakanMedis,
            "penyakit": "",
            "status": this.state.statusTindakanMedis,
            "molekul": "",
            "penulis": "",
            "page": 1,
            "limit": 10,
            "urutan": "created_at DESC",
            isLimitPeriod: true,
        }

        // console.log("body =>", body)
        httprequester.postarticle('tindakan/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            tindakan_penulis: v[GetIndexFromSchema('tindakan_penulis', response.data.result.data.schema)],
                            tindakan_id: v[GetIndexFromSchema('tindakan_id', response.data.result.data.schema)],
                            tindakan_spesialis: v[GetIndexFromSchema('tindakan_spesialis', response.data.result.data.schema)],
                            tindakan_content: v[GetIndexFromSchema('tindakan_content', response.data.result.data.schema)],
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            tindakan_penyakit: v[GetIndexFromSchema('tindakan_penyakit', response.data.result.data.schema)],
                            tindakan_status: v[GetIndexFromSchema('tindakan_status', response.data.result.data.schema)],
                            tindakan_thumbnail: v[GetIndexFromSchema('tindakan_thumbnail', response.data.result.data.schema)],
                            tindakan_slug: v[GetIndexFromSchema('tindakan_slug', response.data.result.data.schema)],
                            tindakan_molekul: v[GetIndexFromSchema('tindakan_molekul', response.data.result.data.schema)],
                            tindakan_video: v[GetIndexFromSchema('tindakan_video', response.data.result.data.schema)],
                            tindakan_title: v[GetIndexFromSchema('tindakan_title', response.data.result.data.schema)],
                        });
                    })
                    this.setState({
                        listTindakanMedis: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("listTindakanMedis => ", this.state.listTindakanMedis)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                // console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    updateStatusTindakanMedis = (value) => {
        let body = {
            "tindakan_id": value.tindakan_id,
            "tindakan_status": value.tindakan_status === 'Published' ? 'Unpublished' : 'Published'
        }
        // console.log("bodyUpdate =>", body)
        httprequester.postarticle('tindakan/update', body, {
            success: async function (response) {
                // console.log("resUpdate => ", response)
                this.setState({
                    alert: true,
                    message: 'success'
                })
                setTimeout(() => {
                    this.getTindakanMedis();
                }, 2000);
                // if (response.data.message == "success") {
                //     this.setState({
                //         listTindakanMedis: data
                //     })
                // }
                // console.log("artikel => ", this.state.listTindakanMedis)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    copyurl = (value) => {
        // console.log('value',value)
        this.setState({
            namaalert: value.tindakan_title,
            alertcopy: true
        })
        navigator.clipboard.writeText(value.tindakan_id)
        setTimeout(() => {
            this.setState({
                alertcopy: false
            })
        }, 3000);
    }

    deleteTindakanMedis = (value) => {
        let body = {
            "tindakan_id": value.tindakan_id,
            "tindakan_status": "Deleted"
        }
        // console.log("bodyDelete =>", body)
        httprequester.postarticle('tindakan/update', body, {
            success: async function (response) {
                // console.log("resDelete => ", response)
                this.setState({
                    message: response.data.message,
                    alert: true
                })
                setTimeout(() => {
                    this.getTindakanMedis();
                }, 2000);
                // if (response.data.message == "success") {
                //     this.setState({
                //         listTindakanMedis: data
                //     })
                // }
                // console.log("artikel => ", this.state.listTindakanMedis)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    render() {
        // console.log("pagenya", this.state.page)
        const option = this.state.listSpesialisasi;
        const { page, totalPage } = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tindakan Medis Spesialistik</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Halaman Utama" breadcrumbItem="Tindakan Medis Spesialistik" />

                        <Row style={{ paddingBottom: 10 }}>
                            <Col xs="12" md="4">
                                <Link to="/tindakanmedis/tambah-tindakan-medis">
                                    <Button color="danger">
                                        Tambah Tindakan Medis Spesialistik
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <div>
                            {this.state.alert ? (
                                <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                                </SweetAlert>
                            ) : null}
                            <Row>
                                <Col xs="12" md="9">
                                    {this.state.alertcopy ? <div>
                                        <Alert
                                            color="primary"
                                            isOpen={this.state.alertcopy}
                                        >
                                            sucess copy url {this.state.namaalert}
                                        </Alert>
                                    </div> : ''}
                                    <Row xs="3">
                                        {this.state.listTindakanMedis.map(function (tindakan, index) {
                                            return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="text-truncate font-size-15" tag="h5" style={{ maxHeight: '50%' }}>
                                                            {tindakan.tindakan_title}
                                                        </CardTitle>
                                                        <p className="text-muted mb-4">{tindakan.tindakan_penulis}</p>
                                                        <CardText>
                                                            {tindakan.tindakan_spesialis}
                                                        </CardText>
                                                        <CardText>
                                                            {tindakan.tindakan_penyakit}
                                                        </CardText>
                                                        <CardText>
                                                            {tindakan.tindakan_molekul}
                                                        </CardText>
                                                        <Row>
                                                            <div className="d-flex flex-wrap gap-3" style={{ paddingBottom: 5, justifyContent: "center", alignItems: "center" }}>
                                                                <div className="btn-group btn-group-lg">
                                                                    {/* <button type="button" className="btn btn-outline-danger" onClick={() => { console.log("btnPreview") }}> <i className="fas fa-eye"></i> </button> */}
                                                                    {/* <Link type="button" className="btn btn-outline-danger" to={`artikel-preview/${tindakan.tindakan_id}-${tindakan.tindakan_slug}`} target="_blank"> <i className="fas fa-eye"></i> </Link> */}
                                                                    <Link type="button" className="btn btn-outline-danger" to={`tindakanmedis/edit-tindakan-medis/${tindakan.tindakan_id}/${tindakan.tindakan_status}`}> <i className="fas fa-edit"></i> </Link>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.deleteTindakanMedis(tindakan) }}> <i className="fas fa-trash"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.copyurl(tindakan) }}> <i className="fas fa-copy"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(tindakan.tindakan_id, "tindakanmedis" )}}> <i className="fas fa-globe"></i> </button>
                                                                    {/* to={`webinar/edittimestamp/${webinar.webinar_id}`} */}
                                                                </div>
                                                            </div>

                                                            <div className="d-grid gap-2">
                                                                <Button color="primary" type="button" className="btn-lg" onClick={() => { this.updateStatusTindakanMedis(tindakan) }}> {tindakan.tindakan_status === 'Published' ? 'Unpublished' : 'Published'} </Button>
                                                            </div>
                                                            {/* <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.updateStatusTindakanMedis(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    {article.article_status === 'Published' ? 'Unpublished' : 'Published'}
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.deleteArtikel(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.deleteArtikel(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </Button>
                                                            </Col> */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }.bind(this))}
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {/* <PaginationItem disabled={this.state.page === 1}>
                                                    <PaginationLink
                                                        previous
                                                        // href="#"
                                                        onClick={() => this.handlePageClick(this.state.page - 1)}
                                                    />
                                                </PaginationItem> */}
                                                {this.state.totalPage.map((v, key) => {
                                                    return <PaginationItem active={this.state.page === v} key={key}>
                                                        <PaginationLink
                                                            onClick={() => this.handlePageClick(v)}
                                                        >
                                                            {v}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                })}
                                                {/* <PaginationItem disabled={this.state.page === this.state.totalPage}>
                                                    <PaginationLink
                                                        next
                                                        // href="#"
                                                        onClick={() => this.handlePageClick(this.state.page + 1)}
                                                    />
                                                </PaginationItem> */}
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={3} lg={4}>
                                    <Card>
                                        <CardBody className="p-4">
                                            <div className="search-box">
                                              <p className="text-muted">Cari</p>
                                              <div className="position-relative">
                                                <input
                                                  type="search"
                                                  className="form-control rounded bg-light border-light"
                                                  placeholder="Cari..."
                                                  onChange={(e) => this.setState({ searchTindakanMedis: e.target.value })}
                                                  value={this.state.searchTindakanMedis}
                                                />
                                                <i className="mdi mdi-magnify search-icon"></i>
                                              </div>
                                            </div>

                                            <hr className="my-4" />

                                            <div>
                                              {/* <p className="text-muted">Tindakan Medis Spesialistik Status</p> */}
                                              <Input
                                                onChange={(e) => this.setState({ statusTindakanMedis: e.target.value })}
                                                type="select"
                                              >
                                                <option value="Published">Published</option>
                                                <option value="Unpublished">Unpublished</option>
                                                <option value="Completed">Completed</option>
                                              </Input>
                                            </div>

                                            <hr className="my-4" />

                                            {/* <div>
                                                <p className="text-muted">Urutkan</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ urutkanTindakanMedis: e.target.value })}
                                                    value={this.urutkanTindakanMedis}
                                                >
                                                    <option value="">Urutkan berdasarkan</option>
                                                    <option value="ASC">
                                                        ASC
                                                    </option>
                                                    <option value="DESC">
                                                        DESC
                                                    </option>
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="name"
                                                onChange={(e) => this.setState({ hasilSpesialisasi: e })}
                                                options={option}
                                                placeholder="Pilih Spesialisasi"
                                                selected={this.state.hasilSpesialisasi}
                                            />

                                            <hr className="my-4" />

                                            {/* <div>
                                                <p className="text-muted">Kategori</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ categoriesArticle: e.target.value })}
                                                    value={this.categoriesArticle}
                                                >
                                                    <option value="">Pilih Kategori</option>
                                                    <option value="Kategori">
                                                        Kategori
                                                    </option>
                                                    <option value="Spesialis">
                                                        Spesialis
                                                    </option>
                                                    <option value="Molekul">
                                                        Molekul
                                                    </option>
                                                    <option value="Penyakit">
                                                        Penyakit
                                                    </option>
                                                    <option value="Penulis">
                                                        Penulis
                                                    </option>
                                                    {this.state.listCategory ? this.state.listCategory.map((cat) => (
                                                        <option key={cat.category_id} value={cat.category_name}>{cat.category_name}</option>
                                                    )) : null}
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            {/* <div>
                                                <p className="text-muted">List {this.state.categoriesArticle}</p>

                                                <Input
                                                    type="select"
                                                    name="select"
                                                    id="select"
                                                    onChange={(e) => this.setState({ c: e.target.value })}
                                                    value={this.valueTindakanMedis}
                                                >
                                                    <option value="">Pilih {this.state.categoriesArticle}</option>
                                                    {this.state.listValue ? this.state.listCategory.map((cat) => (
                                                        <option key={cat.category_id} value={cat.category_name}>{cat.category_name}</option>
                                                    )) : null}
                                                </Input>
                                            </div>

                                            <hr className="my-4" /> */}

                                            <Button color="danger" onClick={() => this.getTindakanMedis()}>
                                                Cari
                                            </Button>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(TindakanMedis)
