import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import httprequester from "../../lib/httprequester"
import { useHistory } from "react-router-dom"

const Perixa = () => {
  const [users, setUsers] = useState([])
  const { SearchBar } = Search
  const history = useHistory()

  // Table columns configuration
  const columns = [
    {
      dataField: "nama_lengkap",
      text: "Name",
      sort: true,
    },
    {
      dataField: "spesialis",
      text: "Specialization",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: (cell, row) => (
        <Button
          color="success"
          className="btn-sm"
          onClick={() => history.push({
            pathname: `perixa/edit-user`,
            state: { user: row, id: row.id }
          })}
        >
          Edit
        </Button>
      ),
    },
  ]

  const defaultSorted = [{
    dataField: "nama_lengkap",
    order: "asc"
  }]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: users.length,
    custom: true,
  }

  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "All", value: users.length },
  ]

  // Fetch users data
  const fetchUsers = () => {
    httprequester.get("admin/get-registered-perixa-user", {
      success: (response) => {
        if (response.data.message === "success") {
          setUsers(response.data.data)
        }
      },
      error: (error) => {
        console.error("Error fetching users:", error)
      },
    })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Perixa Users</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Perixa" breadcrumbItem="Registered Users" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={users}
                        search
                      >
                        {toolkitProps => (
                          <>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="id"
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes="table align-middle table-nowrap"
                                headerWrapperClasses="thead-light"
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone {...paginationProps} />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone {...paginationProps} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Perixa
