import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Alert
} from 'reactstrap';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { GetIndexFromSchema } from '../../helpers/CommonHelper';

import { isEmpty, map, size } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Webinar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            sizePerPage: 10,
            totalPage: [],
            listWebinar: [],
            listCategory: [],
            listValue: [],
            alert: false,
            openmodal: false,
            // modal: false,
            message: '',
            urlimage: '',
            id: null,
            categoriesWebinar: '',
            statusWebinar: 'Published',
            searchWebinar: '',
            valueWebinar: '',
            urutkanWebinar: '',
            namaalert: '',
            alertcopy: false,
            listPenyakit: [],
            listMolekul: [],
            listPengarang: [],
            listSpesialisasi: [],
            hasilPengarang: [],
            hasilPenyakit: [],
            hasilSpesialisasi: [],
            hasilMolekul: [],
        }
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    componentDidMount() {
        this.getWebinar();
        this.getLov()
    }

    getLov() {
        let body = {
            hp_type: "webinar"
        }

        httprequester.postarticle('homepage/lov_content', body, {
            success: async function (response) {

                let { data } = response;
                // let { detailMessage } = response.data;
                this.setState({
                    listMolekul: data.resultMolekul,
                    listPengarang: data.resultPenulis,
                    listPenyakit: data.resultPenyakit,
                    listSpesialisasi: data.resultSpecialist
                })
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handlePageClick = (e) => {
        let a = e
        this.setState({
            page: a
        })

        let penulis = this.state.hasilPengarang[0] ? this.state.hasilPengarang[0] : ''
        let molekul = this.state.hasilMolekul[0] ? this.state.hasilMolekul[0] : ''
        let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let penyakit = this.state.hasilPenyakit[0] ? this.state.hasilPenyakit[0] : ''

        let body = {
            "spesialisasi": spesialisasi,
            "penyakit": penyakit,
            "search": this.state.searchWebinar,
            "value": "",
            "status": this.state.statusWebinar,
            "page": a,
            "limit": this.state.sizePerPage,
            "molekul": molekul,
            "penulis": penulis,
            "urutan": "created_at DESC"
        }

        httprequester.postarticle('webinar/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            webinar_spesialis: v[GetIndexFromSchema('webinar_spesialis', response.data.result.data.schema)],
                            webinar_molekul: v[GetIndexFromSchema('webinar_molekul', response.data.result.data.schema)],
                            webinar_thumbnail: v[GetIndexFromSchema('webinar_thumbnail', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            webinar_video: v[GetIndexFromSchema('webinar_video', response.data.result.data.schema)],
                            webinar_slug: v[GetIndexFromSchema('webinar_slug', response.data.result.data.schema)],
                            webinar_penyakit: v[GetIndexFromSchema('webinar_penyakit', response.data.result.data.schema)],
                            webinar_id: v[GetIndexFromSchema('webinar_id', response.data.result.data.schema)],
                            webinar_content:v[GetIndexFromSchema('webinar_content', response.data.result.data.schema)],
                            webinar_title:v[GetIndexFromSchema('webinar_title', response.data.result.data.schema)],
                            webinar_penulis: v[GetIndexFromSchema('webinar_penulis', response.data.result.data.schema)],
                            webinar_status: v[GetIndexFromSchema('webinar_status', response.data.result.data.schema)],
                        });
                    })
                    this.setState({
                        listWebinar: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("artikel => ", this.state.listWebinar)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })

    }

    reset = () => {

        let body = {
            "spesialisasi": "",
            "penyakit": "",
            "search": "",
            "value": "",
            "status": "Published",
            "page": 1,
            "limit": this.state.sizePerPage,
            "molekul": "",
            "penulis": "",
            "urutan": "created_at DESC"
        }

        // console.log(body)

        this.setState({
            hasilSpesialisasi: [],
            hasilMolekul: [],
            hasilPenyakit: [],
            hasilPengarang: [],
            search: '',
            page: 1
        })

        httprequester.postarticle('webinar/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }
                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            webinar_spesialis: v[GetIndexFromSchema('webinar_spesialis', response.data.result.data.schema)],
                            webinar_molekul: v[GetIndexFromSchema('webinar_molekul', response.data.result.data.schema)],
                            webinar_thumbnail: v[GetIndexFromSchema('webinar_thumbnail', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            webinar_video: v[GetIndexFromSchema('webinar_video', response.data.result.data.schema)],
                            webinar_slug: v[GetIndexFromSchema('webinar_slug', response.data.result.data.schema)],
                            webinar_penyakit: v[GetIndexFromSchema('webinar_penyakit', response.data.result.data.schema)],
                            webinar_id: v[GetIndexFromSchema('webinar_id', response.data.result.data.schema)],
                            webinar_content:v[GetIndexFromSchema('webinar_content', response.data.result.data.schema)],
                            webinar_title:v[GetIndexFromSchema('webinar_title', response.data.result.data.schema)],
                            webinar_penulis: v[GetIndexFromSchema('webinar_penulis', response.data.result.data.schema)],
                            webinar_status: v[GetIndexFromSchema('webinar_status', response.data.result.data.schema)],
                        });
                    })
                    this.setState({
                        listWebinar: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("artikel => ", this.state.listWebinar)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })

    }

    getWebinar = () => {
        let penulis = this.state.hasilPengarang[0] ? this.state.hasilPengarang[0] : ''
        let molekul = this.state.hasilMolekul[0] ? this.state.hasilMolekul[0] : ''
        let spesialisasi = this.state.hasilSpesialisasi[0] ? this.state.hasilSpesialisasi[0] : ''
        let penyakit = this.state.hasilPenyakit[0] ? this.state.hasilPenyakit[0] : ''

        let body = {
            "spesialisasi": spesialisasi,
            "penyakit": penyakit,
            "search": this.state.searchWebinar,
            "value": "",
            "status": this.state.statusWebinar,
            "page": this.state.page,
            "limit": this.state.sizePerPage,
            "molekul": molekul,
            "penulis": penulis,
            "urutan": "created_at DESC",
            isLimitPeriod: true,
        }

        // console.log("body =>", body)
        httprequester.postarticle('webinar/getall', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.result.data
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    // console.log("res2 => ", response.data.result.data.dataRows)
                    let pages = []
                    for (let index = 1; index <= response.data.result.lastPage; index++) {
                        pages.push(index)
                    }

                    // if(response.data.result.data.length)
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            webinar_spesialis: v[GetIndexFromSchema('webinar_spesialis', response.data.result.data.schema)],
                            webinar_molekul: v[GetIndexFromSchema('webinar_molekul', response.data.result.data.schema)],
                            webinar_thumbnail: v[GetIndexFromSchema('webinar_thumbnail', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            webinar_video: v[GetIndexFromSchema('webinar_video', response.data.result.data.schema)],
                            webinar_slug: v[GetIndexFromSchema('webinar_slug', response.data.result.data.schema)],
                            webinar_penyakit: v[GetIndexFromSchema('webinar_penyakit', response.data.result.data.schema)],
                            webinar_id: v[GetIndexFromSchema('webinar_id', response.data.result.data.schema)],
                            webinar_content:v[GetIndexFromSchema('webinar_content', response.data.result.data.schema)],
                            webinar_title:v[GetIndexFromSchema('webinar_title', response.data.result.data.schema)],
                            webinar_penulis: v[GetIndexFromSchema('webinar_penulis', response.data.result.data.schema)],
                            webinar_status: v[GetIndexFromSchema('webinar_status', response.data.result.data.schema)],
                        });
                    })
                    this.setState({
                        listWebinar: data,
                        page: response.data.result.page,
                        sizePerPage: response.data.result.perPage,
                        totalPage: pages,
                    })
                }
                // console.log("artikel => ", this.state.listWebinar)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    updateStatusArtikel = (value) => {
        let body = {
            "webinar_id": value.webinar_id,
            "webinar_status": value.webinar_status === 'Published' ? 'Unpublished' : 'Published'
        }

        let dx = {
            content_id: value.webinar_id,
            content_penulis: value.webinar_penulis,
            content_title: value.webinar_title,
            created_at: value.created_at,
            content_slug: value.webinar_slug,
            content_penyakit: value.webinar_penyakit,
            content_molekul: value.webinar_molekul,
            content_spesialis: value.webinar_spesialis,
            hp_type: "webinar"
        }
        // console.log("bodyUpdate =>", body)
        httprequester.postarticle('webinar/update', body, {
            success: async function (response) {
                // console.log("resUpdate => ", response)
                if(body.webinar_status === "Published"){
                    httprequester.postarticle('homepage/lov_construct', dx, {
                        success: async function (responses) {
                            // console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            console.log("error", err)
                        }.bind(this)
                    })
                }else{
                    httprequester.postarticle('homepage/lov_destruct', dx, {
                        success: async function (responses) {
                            // console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            console.log("error", err)
                        }.bind(this)
                    })
                }
                this.setState({
                    alert: true,
                    message: 'success'
                })
                setTimeout(() => {
                    this.getWebinar();
                }, 2000);
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    copyurl = (value, nama) => {
        this.setState({
            namaalert: nama,
            alertcopy: true
        })
        navigator.clipboard.writeText(value)
        setTimeout(() => {
            this.setState({
                alertcopy: false
            })
        }, 3000);
    }

    direct = (id, status) => {
        // this.setState({
        //     basic: false
        // })
        this.props.history.push(`/webinar/editwebinar/${id}/${status}`)
    }

    directSEO = (id, type) => {
        this.props.history.push(`/seoconfig/${id}/${type}`)
    }

    deleteArtikel = (value) => {
        let body = {
            "webinar_id": value.webinar_id,
            "webinar_status": "Deleted"
        }
        // console.log("bodyDelete =>", body)
        httprequester.postarticle('webinar/update', body, {
            success: async function (response) {
                // console.log("resDelete => ", response)
                this.setState({
                    message: response.data.message,
                    alert: true
                })
                setTimeout(() => {
                    this.getWebinar();
                }, 2000);
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)
        })
    }

    render() {
        // console.log("pagenya", this.state.page)
        const { page, totalPage } = this.state;
        const option = this.state.listSpesialisasi.map(o => o.ri_lov_content_name)
        const optionPengarang = this.state.listPengarang.map(o => o.ri_lov_content_name)
        const optionMolekul = this.state.listMolekul.map(o => o.ri_lov_content_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.ri_lov_content_name)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Webcast</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Webcast" breadcrumbItem="Webcast" />

                        <Row style={{ paddingBottom: 10 }}>
                            <Col xs="12" md="4">
                                <Link to="/webinar/tambahwebinar">
                                    <Button color="danger">
                                        Tambah Webcast
                                    </Button>
                                </Link>
                            </Col>
                        </Row>

                        <div>
                            {this.state.alert ? (
                                <SweetAlert success title={this.state.message} onConfirm={() => this.setState({ alert: false })}>
                                </SweetAlert>
                            ) : null}
                            <Row>
                                <Col xs="12" md="9">
                                    {this.state.alertcopy ? <div>
                                        <Alert
                                            color="primary"
                                            isOpen={this.state.alertcopy}
                                        >
                                            sucess copy url {this.state.namaalert}
                                        </Alert>
                                    </div> : ''}
                                    <Row xs="3">
                                        {this.state.listWebinar.map(function (webinar, index) {
                                            return <Col key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="text-truncate font-size-15" tag="h5" style={{ maxHeight: '50%' }}>
                                                            {webinar.webinar_title}
                                                        </CardTitle>
                                                        <p className="text-muted mb-4">{webinar.webinar_penulis}</p>
                                                        <CardText>
                                                            {webinar.webinar_spesialis}
                                                        </CardText>
                                                        <CardText>
                                                            {webinar.webinar_penyakit}
                                                        </CardText>
                                                        <CardText>
                                                            {webinar.webinar_molekul}
                                                        </CardText>
                                                        <Row>
                                                            <div className="d-flex flex-wrap gap-3" style={{ paddingBottom: 5, justifyContent: "center", alignItems: "center" }}>
                                                                <div className="btn-group btn-group-lg">
                                                                    {/* <button type="button" className="btn btn-outline-danger" onClick={() => { console.log("btnPreview") }}> <i className="fas fa-eye"></i> </button> */}
                                                                    {/* <Link type="button" className="btn btn-outline-danger" to={`artikel-preview/${webinar.webinar_id}-${webinar.webinar_slug}`} target="_blank"> <i className="fas fa-eye"></i> </Link> */}
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.direct(webinar.webinar_id, webinar.webinar_status) }}> <i className="fas fa-edit"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.deleteArtikel(webinar) }}> <i className="fas fa-trash"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.copyurl(webinar.webinar_id, webinar.webinar_title) }}> <i className="fas fa-copy"></i> </button>
                                                                    <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(webinar.webinar_id, "webinar")}}> <i className="fas fa-globe"></i> </button>
                                                                    {/* to={`webinar/edittimestamp/${webinar.webinar_id}`} */}
                                                                </div>
                                                            </div>

                                                            <div className="d-grid gap-2">
                                                                <Button color="primary" type="button" className="btn-lg" onClick={() => { this.updateStatusArtikel(webinar) }}> {webinar.webinar_status === 'Published' ? 'Unpublished' : 'Published'} </Button>
                                                            </div>
                                                            {/* <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.updateStatusArtikel(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    {article.article_status === 'Published' ? 'Unpublished' : 'Published'}
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.deleteArtikel(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => { this.deleteArtikel(article) }}
                                                                // onClick={() => {console.log("tah eta => ", article)}}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </Button>
                                                            </Col> */}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }.bind(this))}
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                                                {/* <PaginationItem disabled={this.state.page === 1}>
                                                    <PaginationLink
                                                        previous
                                                        // href="#"
                                                        onClick={() => this.handlePageClick(this.state.page - 1)}
                                                    />
                                                </PaginationItem> */}
                                                {this.state.totalPage.map((v, key) => {
                                                    return <PaginationItem active={this.state.page === v} key={key}>
                                                        <PaginationLink
                                                            onClick={() => this.handlePageClick(v)}
                                                        >
                                                            {v}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                })}
                                                {/* <PaginationItem disabled={this.state.page === this.state.totalPage}>
                                                    <PaginationLink
                                                        next
                                                        // href="#"
                                                        onClick={() => this.handlePageClick(this.state.page + 1)}
                                                    />
                                                </PaginationItem> */}
                                            </Pagination>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xl={3} lg={4}>
                                    <Card>
                                        <CardBody className="p-4">
                                            <div>
                                                <div className="search-box">
                                                    <p className="text-muted">Cari</p>
                                                    <div className="position-relative">
                                                        <input
                                                            type="search"
                                                            className="form-control rounded bg-light border-light"
                                                            placeholder="Cari..."
                                                            onChange={(e) => this.setState({ searchWebinar: e.target.value })}
                                                            value={this.state.searchWebinar}
                                                        />
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>

                                                <hr className="my-4" />
                                                <Input
                                                    // id="focusAfterClose"
                                                    onChange={(e) => this.setState({ statusWebinar: e.target.value })}
                                                    // onChange= {(e) => console.log(e.target.value) }
                                                    type="select"
                                                >
                                                    <option value="Published">
                                                        Published
                                                    </option>
                                                    <option value="Unpublished">
                                                        Unpublished
                                                    </option>
                                                    <option value="Completed">Completed</option>
                                                </Input>
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPengarang: e })}
                                                    options={optionPengarang}
                                                    placeholder="Pilih Narasumber"
                                                    selected={this.state.hasilPengarang}
                                                />
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilSpesialisasi: e })}
                                                    options={option}
                                                    placeholder="Pilih Spesialisasi"
                                                    selected={this.state.hasilSpesialisasi}
                                                />
                                                <hr className="my-4" />
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    onChange={(e) => this.setState({ hasilPenyakit: e })}
                                                    options={optionPenyakit}
                                                    placeholder="Pilih Penyakit"
                                                    selected={this.state.hasilPenyakit}
                                                />
                                                <hr className="my-4" />
                                            </div>

                                            <Button color="danger" onClick={() => this.getWebinar()}>
                                                Cari
                                            </Button>&nbsp;&nbsp;
                                            <Button color="danger" onClick={() => this.reset()}>
                                                Atur Ulang
                                            </Button>


                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Webinar);
