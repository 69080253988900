import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardImg,
    CardGroup,
    Form,
    Label
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from 'react-router'

import { Typeahead } from 'react-bootstrap-typeahead';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment'


class editEvent extends Component {
    constructor(props) {
        super(props);
        this.fileRef = createRef();
        this.state = {
            basic: false,
            listSpesialistoption: [],
            listLokasioption: [],
            listEvent: [],
            hasil: [],
            hasilLokasi: [],
            upload:null,
            filefoto: null,
            nama_acara: '',
            alamat: '',
            tanggal: '',
            waktu: '',
            articleKonten: '',
            jenis_acara: '',
            link_acara: '',
            message: '',
            org_id: null,
            image: '',
            chspe: false,
            spesialisasi: '',
            lokasi: '',
            event_id: '',
            chsped: false,
            deskripsiedit: '',
            chspea: false,
            chspel: false,
            admin_type: '',
            listPenyelenggara: [],
            hasilPenyelenggara: [],
            penyelenggaraAdmin: '',
            chspepe: false,
            waktu_list: null,
            chspew: false,
            deskripsieditsave: '',
            tanggal_akhir:'',
            waktu_akhir:'',
            waktu_selesai:'',
            chspewa:false,
            prosess:false
        };
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    onCancel = () => {
        this.setState({
            basic: false
        })
    }

    handleEditorChange = (e) => {
        // console.log('as', e.target.getContent())
        this.setState({
            deskripsieditsave: e.target.getContent()
        })
    }

    direct = () => {
        this.setState({
            basic: false
        })
        this.props.history.push('/event')
    }



    getLOV = () => {
        httprequester.get('event/lov', {
            success: async function (response) {
                // console.log("res => ", response.data.detailSpecialist)
                if (response.data.message == "success") {
                    this.setState({
                        listPenyelenggara: response.data.detailOrganisasi,
                        listSpesialistoption: response.data.detailSpecialist,
                        listLokasioption: response.data.detailCity
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handleupload = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            this.setState({
                user_image: [reader.result]
            })
        }.bind(this);
        this.setState({
            upload: file,
            user_image: url
        })
    }

    getAcara = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        console.log('hasil', routerSplitter)
        let body = {
            "event_id": routerSplitter[4]
        }
        // console.log("body =>", body)
        httprequester.postnobody('event/detail', body, {
            success: async function (response) {
                let data = []
                response.data.settings.datarows.forEach(async (v, key) => {
                    await data.push({
                        event_date_end: moment(v[0]).format('DD MMM YYYY hh:mm:ss'),
                        event_thumbnail: v[1],
                        event_location: v[2],
                        event_description: v[3],
                        event_spesialisasi: v[4],
                        created_at: v[5],
                        event_organization: v[6],
                        created_by: v[7],
                        myfile: v[8],
                        event_id: v[9],
                        event_type: v[10],
                        event_date: moment(v[11]).format('DD MMM YYYY hh:mm:ss'),
                        event_link: v[12],
                        event_name: v[13],
                        event_status: v[14]                       
                    })
                })

                console.log('data',data)

                let edit = data.find(i => i.event_id == routerSplitter[4])
                // console.log('as',edit)
                this.setState({
                    listEvent: data,
                    nama_acara: edit.event_name,
                    link_acara: edit.event_link,
                    tanggal: moment(edit.event_date).format('YYYY-MM-DD'),
                    tanggal_akhir: moment(edit.event_date_end).format('YYYY-MM-DD'),
                    penyelenggara: edit.event_organization,
                    penyelenggaraAdmin: edit.event_organization,
                    spesialisasi: edit.event_spesialisasi,
                    waktu_list: moment(edit.event_date).format('h:mm'),
                    waktu_akhir: moment(edit.event_date_end).format('h:mm'),
                    jenis_acara: edit.event_type.charAt(0).toUpperCase() + edit.event_type.slice(1),
                    lokasi: edit.event_location,
                    user_image: edit.event_thumbnail,
                    deskripsiedit: edit.event_description,
                    event_id: edit.event_id
                })
                // console.log("artikel => ", this.state.listArtikel)
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handleclick = () => {
        this.fileRef.current.click()
    }

    componentDidMount() {
        const { org_name, org_id, admin_type } = JSON.parse(localStorage.getItem('user'))
        this.setState({
            penyelenggara: org_name,
            org_id: org_id,
            admin_type: admin_type
        })
        // console.log('ad', org_name)
        this.getLOV();
        this.getAcara()

    }

    saveEvent = () => {
        // let event_id = moment().format('YYYYMMDDHHmmss') + this.state.org_id
        if (this.state.admin_type == 'admin') {
            let [startHour, startMinute] = this.state.waktu_list.split(':');
            // let c = this.state.waktu_list.charAt(0);
            let waktulist = null
            let waktuakhir = null
            if (Number(startHour) < 10){
                waktulist = '0' + this.state.waktu_list
            } else {
                waktulist = this.state.waktu_list
            }

            let [endHour, endMinute] = this.state.waktu_akhir.split(':');
            if (Number(endHour) < 10) {
                waktuakhir = '0' + this.state.waktu_akhir
            } else {
                waktuakhir = this.state.waktu_akhir
            }

            // console.log('as',c);
            let event_date = this.state.waktu ? this.state.tanggal + 'T' + this.state.waktu + ':00Z' : this.state.tanggal + 'T' + waktulist + ':00Z'
            let event_date_end = this.state.waktu_selesai ? this.state.tanggal_akhir + 'T' + this.state.waktu_selesai + ':00Z' : this.state.tanggal_akhir + 'T' + waktuakhir + ':00Z'
            let router = window.location.href
            let routerSplitter = router.split("/");
            console.log('waktuS', this.state.waktu_selesai);
            console.log('waktu', this.state.waktu);
            // console.log('tgle',event_date)
            // console.log('tgl', this.state.tanggal)

            if (this.state.upload == null) {
                let formData = new FormData();
                formData.append("event_id", this.state.event_id);
                formData.append("event_name", this.state.nama_acara);
                formData.append("event_organization", this.state.hasilPenyelenggara.length > 0 ? this.state.hasilPenyelenggara[0] : this.state.penyelenggaraAdmin);
                formData.append("event_type", this.state.jenis_acara);
                formData.append("event_spesialisasi", this.state.hasil.toString() ? this.state.hasil.toString() : this.state.spesialisasi);
                formData.append("event_date", event_date);
                formData.append("event_date_end", event_date_end)
                formData.append("event_location", this.state.hasilLokasi.length > 0 ? this.state.hasilLokasi[0] : this.state.lokasi);
                formData.append("event_description", this.state.deskripsieditsave ? this.state.deskripsieditsave : this.state.deskripsiedit);
                formData.append("event_link", this.state.link_acara);
                formData.append("created_by", this.state.penyelenggara);
                formData.append("event_status", routerSplitter[5]);
                // formData.append("myfile", routerSplitter[5]);
                // myfile

                // console.log('data', formData)
                this.setState({
                    prosess:true
                })
                httprequester.postupload('event/edit', formData, {
                    success: async function (response) {
                        if (response.data.message == "success") {
                            this.setState({
                                basic: true,
                                message: response.data.detailMessage,
                                prosess:false
                            })
                            // this.fileUploads.current
                            // e.target.value = null;
                            // this.fileUploads.current.value
                            // this.getLOV()
                        }
                        // console.log('ini', this.fileUploads)
                    }.bind(this),
                    error: function (err) {

                    }.bind(this)

                })
            } else {
                let formData = new FormData();
                formData.append("myfile", this.state.upload);
                formData.append("event_id", this.state.event_id);
                formData.append("event_name", this.state.nama_acara);
                formData.append("event_organization", this.state.hasilPenyelenggara.length > 0 ? this.state.hasilPenyelenggara[0] : this.state.penyelenggaraAdmin);
                formData.append("event_type", this.state.jenis_acara);
                formData.append("event_spesialisasi", this.state.hasil.toString() ? this.state.hasil.toString() : this.state.spesialisasi);
                formData.append("event_date", event_date);
                formData.append("event_date_end", event_date_end)
                formData.append("event_location", this.state.hasilLokasi.length > 0 ? this.state.hasilLokasi[0] : this.state.lokasi);
                formData.append("event_description", this.state.deskripsieditsave ? this.state.deskripsieditsave : this.state.deskripsiedit);
                formData.append("event_link", this.state.link_acara);
                formData.append("created_by", this.state.penyelenggara);
                formData.append("event_status", routerSplitter[5]);

                // console.log('data', formData)
                this.setState({
                    prosess:true
                })
                httprequester.postupload('event/edit', formData, {
                    success: async function (response) {
                        if (response.data.message == "success") {
                            this.setState({
                                basic: true,
                                message: response.data.detailMessage,
                                prosess:false
                            })
                        }
                        // console.log('ini', this.fileUploads)
                    }.bind(this),
                    error: function (err) {

                    }.bind(this)

                })
            }

        } else {
            let a = this.state.waktu_list.charAt(0);
            let waktulist = null
            let waktuakhir = null
            if (a < 10) {
                waktulist = '0' + this.state.waktu_list
            } else {
                waktulist = this.state.waktu_list
            }

            let b = this.state.waktu_akhir.charAt(0);
            if (b < 10) {
                waktuakhir = '0' + this.state.waktu_akhir
            } else {
                waktuakhir = this.state.waktu_akhir
            }

            let event_date = this.state.tanggal + 'T' + this.state.waktu_list ? this.state.waktu_list : this.state.waktu + ':00Z'
            let event_date_end = this.state.tanggal_akhir + 'T' + this.state.waktu_akhir ? this.state.waktu_akhir : this.state.waktu_selesai + ':00Z'
            let router = window.location.href
            let routerSplitter = router.split("/");

            if (this.state.upload == null) {
                let formData = new FormData();
                formData.append("event_id", this.state.event_id);
                formData.append("event_name", this.state.nama_acara);
                formData.append("event_organization", this.state.hasilPenyelenggara.length > 0 ? this.state.hasilPenyelenggara[0] : this.state.penyelenggara);
                formData.append("event_type", this.state.jenis_acara);
                formData.append("event_spesialisasi", this.state.hasil.toString() ? this.state.hasil.toString() : this.state.spesialisasi);
                formData.append("event_date", event_date);
                formData.append("event_date_end", event_date_end)
                formData.append("event_location", this.state.hasilLokasi.length > 0 ? this.state.hasilLokasi[0] : this.state.lokasi);
                formData.append("event_description", this.state.deskripsieditsave ? this.state.deskripsieditsave : this.state.deskripsiedit);
                formData.append("event_link", this.state.link_acara);
                formData.append("created_by", this.state.penyelenggara);
                formData.append("event_status", routerSplitter[5]);

                // console.log('data', formData)
                this.setState({
                    prosess:true
                })
                httprequester.postupload('event/edit', formData, {
                    success: async function (response) {
                        if (response.data.message == "success") {
                            this.setState({
                                basic: true,
                                message: response.data.detailMessage,
                                prosess:false
                            })
                            // this.fileUploads.current
                            // e.target.value = null;
                            // this.fileUploads.current.value
                            // this.getLOV()
                        }
                        console.log('ini', this.fileUploads)
                    }.bind(this),
                    error: function (err) {

                    }.bind(this)

                })

            } else {
                let formData = new FormData();
                formData.append("myfile", this.state.upload);
                formData.append("event_id", this.state.event_id);
                formData.append("event_name", this.state.nama_acara);
                formData.append("event_organization", this.state.hasilPenyelenggara.length > 0 ? this.state.hasilPenyelenggara[0] : this.state.penyelenggara);
                formData.append("event_type", this.state.jenis_acara);
                formData.append("event_spesialisasi", this.state.hasil.toString() ? this.state.hasil.toString() : this.state.spesialisasi);
                formData.append("event_date", event_date);
                formData.append("event_date_end", event_date_end)
                formData.append("event_location", this.state.hasilLokasi.length > 0 ? this.state.hasilLokasi[0] : this.state.lokasi);
                formData.append("event_description", this.state.deskripsieditsave ? this.state.deskripsieditsave : this.state.deskripsiedit);
                formData.append("event_link", this.state.link_acara);
                formData.append("created_by", this.state.penyelenggara);
                formData.append("event_status", routerSplitter[5]);

                // console.log('data', formData)
                this.setState({
                    prosess:true
                })
                httprequester.postupload('event/edit', formData, {
                    success: async function (response) {
                        if (response.data.message == "success") {
                            this.setState({
                                basic: true,
                                message: response.data.detailMessage,
                                prosess:false
                            })
                            // this.fileUploads.current
                            // e.target.value = null;
                            // this.fileUploads.current.value
                            // this.getLOV()
                        }
                        console.log('ini', this.fileUploads)
                    }.bind(this),
                    error: function (err) {

                    }.bind(this)

                })
            }
        }
    }

    render() {
        // console.log("hacep => ", this.state.articleKonten)
        const { editorState } = this.state;



        const option = this.state.listSpesialistoption.map(o => o.specialist_name)
        const optionLokasi = this.state.listLokasioption.map(o => o.city_name)
        const optionPenyelenggara = this.state.listPenyelenggara.map(o => o.org_name)

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Edit Acara || Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Acara" breadcrumbItem="Edit Acara" />

                        {this.state.basic ? (
                            <SweetAlert success title={this.state.message} onConfirm={this.direct}>
                            </SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <AvForm onValidSubmit={this.saveEvent}>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="row mb-4">
                                                    <Label for="exampleEmail">
                                                        Foto
                                                    </Label>
                                                    <CardGroup>
                                                        <Card>
                                                            <CardImg
                                                                alt="Card image cap"
                                                                src={this.state.user_image}
                                                                top
                                                                width="100%"
                                                            />
                                                            <CardBody>
                                                                <Button style={{ width: '100%' }} onClick={this.handleclick}>
                                                                    Upload Foto
                                                                </Button>
                                                                <input style={{ display: 'none' }} type="file" accept=".png,.jpg,.jpeg" ref={this.fileRef} onChange={this.handleupload} />
                                                            </CardBody>
                                                        </Card>
                                                    </CardGroup>
                                                    {/* <AvField
                                                        name="file"
                                                        type="file"
                                                        accept=".png,.jpg,.jpeg" 
                                                        onChange={(e) => this.setState({ filefoto: e.target.files[0] })}
                                                        value={this.state.image} required /> */}
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="judul"
                                                        label="Judul"
                                                        type="text"
                                                        onChange={(e) => this.setState({ nama_acara: e.target.value })}
                                                        value={this.state.nama_acara} required />
                                                </div>
                                                <div className="row mb-4">
                                                    {this.state.admin_type == 'organisasi' ?
                                                        <AvField
                                                            name="penyelenggara"
                                                            label="Penyelenggara"
                                                            type="text"
                                                            value={this.state.penyelenggara}
                                                            disabled /> : this.state.admin_type == "admin" ?
                                                            <div>
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Label for="exampleSpesialisasi">
                                                                        Penyelenggara
                                                                    </Label>
                                                                    <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspepe: !this.state.chspepe })} >ubah</a>
                                                                </div>
                                                                {(!this.state.chspepe) ?
                                                                    <AvField
                                                                        name="judul"
                                                                        disabled
                                                                        type="text"
                                                                        value={this.state.penyelenggaraAdmin} required />
                                                                    : <Typeahead
                                                                        id="basic-typeahead-single"
                                                                        labelKey="name"
                                                                        onChange={(e) => this.setState({
                                                                            hasilPenyelenggara: e
                                                                        })}
                                                                        options={optionPenyelenggara}
                                                                        placeholder="pilih penyelenggara"
                                                                        selected={this.state.hasilPenyelenggara}
                                                                    />
                                                                }
                                                            </div>
                                                            : ''
                                                    }


                                                </div>
                                                <div className="row mb-4">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Label for="exampleSpesialisasi">
                                                            Spesialisasi
                                                        </Label>
                                                        <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspe: !this.state.chspe })} >ubah</a>
                                                    </div>
                                                    {(!this.state.chspe) ?
                                                        <AvField
                                                            name="judul"
                                                            disabled
                                                            type="text"
                                                            value={this.state.spesialisasi} required />
                                                        : <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            labelKey="name"
                                                            onChange={(e) => this.setState({ hasil: e })}
                                                            options={option}
                                                            placeholder="pilih spesialisasi"
                                                            selected={this.state.hasil}
                                                        />
                                                    }

                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-6">
                                                        <AvField
                                                            name="tanggal"
                                                            label="Tanggal Mulai"
                                                            type="date"
                                                            onChange={(e) => this.setState({ tanggal: e.target.value })}
                                                            value={this.state.tanggal}
                                                            required />
                                                    </div>
                                                    <div className="col-6">
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Label for="exampleSpesialisasi">
                                                                Waktu Mulai
                                                            </Label>
                                                            <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspew: !this.state.chspew })} >ubah</a>
                                                        </div>
                                                        {/* <Label htmlFor="formrow-firstname-Input">Penulis</Label> */}
                                                        {(!this.state.chspew) ?
                                                            <AvField
                                                                name="judul"
                                                                disabled
                                                                type="text"
                                                                value={this.state.waktu_list} required />
                                                            : <AvField
                                                                name="waktu"
                                                                type="time"
                                                                onChange={(e) => this.setState({ waktu: e.target.value })}
                                                                value={this.state.waktu}
                                                                required />
                                                        }

                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-6">
                                                        <AvField
                                                            name="tanggal"
                                                            label="Tanggal Akhir"
                                                            type="date"
                                                            onChange={(e) => this.setState({ tanggal_akhir: e.target.value })}
                                                            value={this.state.tanggal_akhir}
                                                            required />
                                                    </div>
                                                    <div className="col-6">
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Label for="exampleSpesialisasi">
                                                                Waktu Akhir
                                                            </Label>
                                                            <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspewa: !this.state.chspewa })} >ubah</a>
                                                        </div>
                                                        {/* <Label htmlFor="formrow-firstname-Input">Penulis</Label> */}
                                                        {(!this.state.chspewa) ?
                                                            <AvField
                                                                name="judul"
                                                                disabled
                                                                type="text"
                                                                value={this.state.waktu_akhir} required />
                                                            : <AvField
                                                                name="waktu"
                                                                type="time"
                                                                onChange={(e) => this.setState({ waktu_selesai: e.target.value })}
                                                                value={this.state.waktu_selesai}
                                                                required />
                                                        }

                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <AvField
                                                        name="linkacara"
                                                        label="Link Acara"
                                                        type="text"
                                                        onChange={(e) => this.setState({ link_acara: e.target.value })}
                                                        value={this.state.link_acara}
                                                    />
                                                </div>
                                                <div className="row mb-4">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Label for="exampleSpesialisasi">
                                                            Lokasi
                                                        </Label>
                                                        <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspel: !this.state.chspel })} >ubah</a>
                                                    </div>
                                                    {(!this.state.chspel) ?
                                                        <AvField
                                                            name="judul"
                                                            disabled
                                                            type="text"
                                                            value={this.state.lokasi} required />
                                                        : <Typeahead
                                                            id="basic-typeahead-single"
                                                            labelKey="name"
                                                            onChange={(e) => this.setState({ hasilLokasi: e })}
                                                            options={optionLokasi}
                                                            placeholder="pilih lokasi"
                                                            selected={this.state.hasilLokasi}
                                                        />
                                                    }
                                                </div>
                                                <div className="row mb-4">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Label for="exampleSpesialisasi">
                                                            Jenis Acara
                                                        </Label>
                                                        <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspea: !this.state.chspea })} >ubah</a>
                                                    </div>
                                                    {
                                                      !this.state.chspea
                                                        ? <AvField
                                                            name="judul"
                                                            disabled
                                                            type="text"
                                                            value={this.state.jenis_acara}
                                                            required
                                                          />
                                                        : <AvField type="select" name="select" onChange={(e) => this.setState({ jenis_acara: e.target.value })}>
                                                            <option>pilih</option>
                                                            <option value="Online">Online</option>
                                                            <option value="Offline">Offllne</option>
                                                            <option value="Hybrid">Hybrid</option>
                                                          </AvField>
                                                    }
                                                </div>
                                                <div className="row mb-4">
                                                    <Label for="exampledeskripsi">
                                                        Deskripsi
                                                    </Label>
                                                    <Form method="post">
                                                        <Editor
                                                            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                            content={this.state.deskripsieditsave}
                                                            initialValue={this.state.deskripsiedit}
                                                            init={{
                                                                height: 500,
                                                                menubar: false,
                                                                plugins: [
                                                                    'print preview',
                                                                    'importcss searchreplace autolink autosave save',
                                                                    'directionality  visualblocks visualchars fullscreen image link media',
                                                                    'template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists',
                                                                    'wordcount imagetools textpattern noneditable help',
                                                                    'charmap quickbars emoticons',

                                                                ],
                                                                toolbar:
                                                                    'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | image',
                                                                // images_upload_handler: function (blobInfo, success, failure) {
                                                                //     let data = new FormData();
                                                                //     data.append('myfile', blobInfo.blob(), blobInfo.filename());
                                                                //     console.log(data)
                                                                //     axios.post('http://127.0.0.1:3333/api/v1/filemanager/uploadtest', data)
                                                                //         .then(function (res) {
                                                                //             success(res.data.detailMessage);
                                                                //         })
                                                                //         .catch(function (err) {
                                                                //             console.log(err)
                                                                //             failure('HTTP Error: ' + err.message);
                                                                //         });
                                                                // }
                                                            }}
                                                            onChange={this.handleEditorChange}
                                                        />
                                                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chsped: !this.state.chsped })} >ubah</a>
                                                        </div>
                                                        {(!this.state.chsped) ?
                                                            <AvField
                                                                name="judul"
                                                                disabled
                                                                type="textarea"
                                                                value={this.state.deskripsiedit} required />
                                                            : <Editor
                                                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                                initialValue={this.state.deskripsi}
                                                                init={{
                                                                    height: 500,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'print preview powerpaste casechange',
                                                                        'importcss searchreplace autolink autosave save',
                                                                        'directionality advcode visualblocks visualchars fullscreen image link media mediaembed',
                                                                        'template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists',
                                                                        'checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter',
                                                                        'permanentpen pageembed charmap  mentions quickbars linkchecker emoticons advtable export',

                                                                    ],
                                                                    toolbar:
                                                                        'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent',
                                                                    images_upload_handler: function (blobInfo, success, failure) {
                                                                        let data = new FormData();
                                                                        data.append('myfile', blobInfo.blob(), blobInfo.filename());
                                                                        console.log(data)
                                                                        axios.post('http://127.0.0.1:3333/api/v1/filemanager/uploadtest', data)
                                                                            .then(function (res) {
                                                                                success(res.data.detailMessage);
                                                                            })
                                                                            .catch(function (err) {
                                                                                console.log(err)
                                                                                failure('HTTP Error: ' + err.message);
                                                                            });
                                                                    }
                                                                }}
                                                                onChange={this.handleEditorChange}
                                                            />
                                                        } */}

                                                    </Form>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 10 }}>
                                <Col xs="12" md="12">
                                    <Card>
                                        <Button color="danger" >
                                            Simpan Acara
                                        </Button>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(editEvent);
