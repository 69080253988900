import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Label
} from "reactstrap";
// Modal, ModalHeader, ModalBody, ModalFooter
import MetaTags from "react-meta-tags";
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import httprequester from "../../lib/httprequester";

class VerifikasiNPA extends Component {
  
  userData = null;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      listVerifikasiNPA: [],
      alert: false,
      message: "",
      card_url: "",
      id: null,
      confirm_both: false,
      popupapprove: false,
      loading: false,
      card_status: {},
      popupreject: false,
      popupkartunpa: false,
      npa_no: '',
      npa_name: '',
      npa_id: 0,
      reason: '',
    };
    this.userData = JSON.parse(localStorage.getItem('user'));
  }

  componentDidMount() {
    this.getListVerifikasiNPA('W');
  }

  // static propTypes = {
  //     history: PropTypes.object.isRequired
  // }

  getListVerifikasiNPA = (status) => {
    let body = {
      card_status: status,
      page: 1,
      limit: 10,
    };
    // console.log("isi")
    httprequester.postnobody("admin/user_card_view", body, {
      success: async function (response) {
        let data = [];
        if (response.data.message == "success") {
          console.log("response", response.data.detailMessage)
          
          response.data.detailMessage.forEach(async (v, key) => {
            await data.push({
              npa_no: v.npa_no,
              user_name: v.user_name,
              user_email: v.user_email,
              npa_name: v.npa_name,
              npa_id: v.npa_id,
              card_status: 
                v.card_status == "W"
                  ? "Waiting"
                  : v.card_status == "R"
                  ? "Reject"
                  : v.card_status == "A"
                  ? "Approve"
                  : "",
              created_at: v.created_at,
              card_url: v.card_url,
              card_id: v.card_id,
              user_id: v.user_id,
              // reason: "",
            });
          });
          // console.log("isi2", data)
          
          // console.log('isi', this.state.listVerifikasiNPA)
        }
        this.setState({
          listVerifikasiNPA: data,
        });
      }.bind(this),
      error: function (err) {}.bind(this),
    });
  };

  handleselect = e => {
    // this.setState({ statusNPA: e.target.value });
    // let body = {
    //   card_status: e.target.value,
    //   page: 1,
    //   limit: 10,
    // };

    this.getListVerifikasiNPA(e.target.value);

    // httprequester.postnobody("admin/user_card_view", body, {
    //   success: async function (response) {
    //     if (response.data.message == "success") {
    //       let data = [];
    //       response.data.detailMessage.forEach(async (v, key) => {
    //         await data.push({
    //           npa_no: v.npa_no,
    //           user_name: v.user_name,
    //           user_email: v.user_email,
    //           npa_name: v.npa_name,
    //           npa_id: v.npa_id,
    //           card_status:
    //             v.card_status == "W"
    //               ? "Waiting"
    //               : v.card_status == "R"
    //               ? "Reject"
    //               : v.card_status == "A"
    //               ? "Approve"
    //               : "",
    //           created_at: v.created_at,
    //           card_url: v.card_url,
    //           card_id: v.card_id,
    //         });
    //       });

    //       this.setState({
    //         listVerifikasiNPA: data,
    //       });
    //     }
    //   }.bind(this),
    //   error: function (err) {}.bind(this),
    // });
  };


  popUpGenerateKartuNPA = row => {
    let check = this.state.listVerifikasiNPA.find(k => k.card_id == row);
    this.setState({
      popupkartunpa: true,
      card_url: check.card_url,
    });
  };

  popUpGenerateReject = row => {
    let check = this.state.listVerifikasiNPA.find(k => k.card_id == row);
    this.setState({
      popupreject: true,
      card_status: check.card_status,
      card_id: row,
      npa_name: check.npa_name,
      npa_id: check.npa_id,
      npa_no: check.npa_no,
      user_id: check.user_id,
    });
    // console.log("isipopup", check)
  };

  popUpGenerateApprove = row => {
    let check = this.state.listVerifikasiNPA.find(k => k.card_id == row);
    // console.log("Isi", check)
    this.setState({
      popupapprove: true,
      card_status: check.card_status,
      card_id: row,
      npa_name: check.npa_name,
      npa_id: check.npa_id,
      npa_no: check.npa_no,
      user_id: check.user_id,
    });
  };
  

  generateReject = () => {
    this.setState({
      popupreject: false,
      loading: true,
    });

    this.getListVerifikasiNPA('W');
 
    let { npa_id, npa_name, npa_no, reason, user_id } = this.state;
    let body = {
      card_id: this.state.card_id,
      card_status: this.state.card_status == 'Approve' ? 'R' : this.state.card_status == 'Waiting' ? 'R' : '',
      npa_id,
      npa_name,
      npa_no,
      reason,
      user_id,
      admin: this.userData?.admin_name
    };
    
    this.setState({
      reason: ''
    })
    
    console.log('isi2', body)

      
   
    httprequester.postnobody("admin/verification_status_user", body, {
      success: async function (response) {
        //  if (response.data.message == "success") {
            // console.log(response);
        this.setState({
          message: response.data.detailMessage,
          loading: false,
          alert: true,
        });
        // this.getListVerifikasiNPA();
    // }
      }.bind(this),
      error: function (err) {}.bind(this),
    });

  };

  closeApprove = () => {
    let select = document.querySelector('#mySelect');
    select.value = 'W'
    this.setState ({
        alert: false,    
    })
    this.getListVerifikasiNPA('W');
  } 
  

  generateApprove = () => {
    this.setState({
      popupapprove: false,
      loading: true,
    });
    
    this.getListVerifikasiNPA('W');

    let { npa_id, npa_name, npa_no, user_id } = this.state;
    let body = {
      card_id: this.state.card_id,
      card_status: this.state.card_status == 'Reject' ? 'A' : this.state.card_status == 'Waiting' ? 'A' : '',
      npa_id,
      npa_name,
      npa_no,
      user_id,
      admin: this.userData?.admin_name
    };
    
    console.log('>>> accept this', body);
    
    httprequester.postnobody("admin/verification_status_user", body, {
      success: async function (response) {
        // if (response.data.message == "success") {
        this.setState({
          message: response.data.detailMessage,
          loading: false,
          alert: true,
        });
      }.bind(this),
      error: function (err) {}.bind(this),
    });
    //
  };

  buttonverifikasi = (row) => {
    let status = this.state.listVerifikasiNPA.find(b => b.card_id == row);
    return (
      <div>
        <Button
          color="primary"
          className="btn-sm"
          onClick={e => {
            this.popUpGenerateKartuNPA(row);
          }}
        >
          Lihat Kartu NPA
        </Button>
        &nbsp;
        {status.card_status == "Reject" ? (
          <Button
            color="success"
            className="btn-sm"
            onClick={e => {
              this.popUpGenerateApprove(row);
            }}
          >
            Approve
          </Button>
        ) : status.card_status == "Waiting" ? (
          <Button
            color="success"
            className="btn-sm"
            onClick={e => {
              this.popUpGenerateApprove(row);
            }}
          >
            Approve
          </Button>
        ) : (
          ""
        )}
        &nbsp;
        {status.card_status == "Approve" ? (
          <Button
            color="danger"
            className="btn-sm"
            onClick={e => {
              this.popUpGenerateReject(row);
            }}
          >
            Reject
          </Button>
        ) : status.card_status == "Waiting" ? (
          <Button
            color="danger"
            className="btn-sm"
            onClick={e => {
              this.popUpGenerateReject(row);
            }}
          >
            Reject
          </Button>
        ) : (
          ""
        )}
        &nbsp;
      </div>
    );
  };

  render() {
    const columns = [
      {
        dataField: "npa_no",
        text: "Nomor NPA",
        sort: true,
      },
      {
        dataField: 'user_name',
        text: "Nama User",
        sort: true,
      },
      {
        dataField: 'user_email',
        text: 'Email',
        sort: true
      },
      {
        dataField: "npa_name",
        text: "Nama NPA",
        sort: true,
      },
      {
        dataField: "card_status",
        text: "Status",
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Tanggal NPA",
        sort: true,
        formatter: row => {
          if (row !== "-") {
            return <div>{moment(row).format("YYYY-MM-DD HH:mm:ss")}</div>;
          }
          return <div>{row}</div>;
        },
      },
      {
        dataField: "card_id",
        text: "Action",
        sort: true,
        formatter: (row) => {
          return <div>{this.buttonverifikasi(row)}</div>;
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: "created_at",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.listVerifikasiNPA.length,
      custom: true,
    };

    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.listVerifikasiNPA.length },
    ];

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Verifikasi NPA | Ruang Ilmiah</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Halaman Utama"
              breadcrumbItem="Verifikasi NPA"
            />
          </Container>
          <div className="container-fluid">
            {this.state.popupkartunpa ? (
              <SweetAlert
                title="Kartu NPA"
                onCancel={() => this.setState({ popupkartunpa: false })}
                showCancel={true}
                showConfirm={false}
                style={{ width: "70em" }}
              >
                <form>
                  <img src={this.state.card_url} width={"100%"} />
                </form>
              </SweetAlert>
            ) : null}
            {this.state.popupapprove ? 
              <SweetAlert
                title="Approve"
                onConfirm={() => this.generateApprove()}
                onCancel={() => this.setState({ popupapprove: false })}
                showCancel={true}
                showConfirm={true}
                type={'controlled'}
                dependencies={[this.state.npa_name, this.state.npa_no]}
              >
                {/* <p>{`Apakah Anda Yakin Akan Approve ?`}</p>
              </SweetAlert>
             : null */}
                  {(renderProps) => (
                    <form>
                      <div style={{textAlign: 'left', display:'flex', justifyContent:'flex-start' }}>
                      <label style={{fontWeight: 'bold'}}>
                        Nomor NPA
                      <div  style={{width: '195%',  display:'flex', justifyContent:'flex-start' }}>
                      <input
                        type={'text'}
                        ref={renderProps.setAutoFocusInputRef}
                        className="form-control"
                        value={this.state.npa_no}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ npa_no: e.target.value })}
                        placeholder={'Nomor NPA'}
                      />
                      </div>
                      </label>
                      </div>
                      <br />
                      <div style={{textAlign: 'left', display:'flex', justifyContent:'flex-start' }}>
                      <label style={{fontWeight: 'bold'}}>
                        Nama NPA
                      <div style={{width: '195%',  display:'flex', justifyContent:'flex-start' }}>
                      <input
                        type={'text'}
                        className="form-control"
                        value={this.state.npa_name}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ npa_name: e.target.value })}
                        placeholder={'Nama NPA'}
                      />
                      </div>
                      </label>
                      </div>
                    </form>
                  )}
                </SweetAlert> : null
            }

            {this.state.popupreject ? 
              <SweetAlert
                title="Reject"
                onConfirm={() => this.generateReject()}
                onCancel={() => this.setState({ popupreject: false, reason: ''})}
                showCancel={true}
                showConfirm={true}
                type={'controlled'}
                dependencies={[this.state.npa_name, this.state.npa_no]}
              >
                {/* <p>{`Apakah Anda Yakin Akan Reject ?`}</p>
              </SweetAlert>
            ) : null */}
                  {(renderProps) => (
                    <form>
                      <div style={{textAlign: 'left', display:'flex', justifyContent:'flex-start' }}>
                      <label style={{fontWeight: 'bold'}}>
                        Nomor NPA
                      <div  style={{width: '195%',  display:'flex', justifyContent:'flex-start' }}>
                      <input
                        disabled
                        type={'text'}
                        ref={renderProps.setAutoFocusInputRef}
                        className="form-control"
                        value={this.state.npa_no}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ npa_no: e.target.value })}
                        placeholder={'Nomor NPA'}
                      />
                      </div>
                      </label>
                      </div>
                    
                      <div style={{textAlign: 'left', display:'flex', justifyContent:'flex-start' }}>
                      <label style={{fontWeight: 'bold'}}>
                        Nama NPA
                      <div style={{width: '195%',  display:'flex', justifyContent:'flex-start' }}>
                      <input
                        disabled
                        type={'text'}
                        className="form-control"
                        value={this.state.npa_name}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                        onChange={(e) => this.setState({ npa_name: e.target.value })}
                        placeholder={'Nama NPA'}
                      />
                      </div>
                      </label>
                      </div>
                      <br/>
                      <div className="mb-3" style={{textAlign: 'left'}}>
                      <label style={{fontWeight: 'bold'}}>Reason Reject</label>
                      <Input 
                        type="textarea" 
                        style={{width: '96%', display:'flex', justifyContent:'flex-start'}}
                        className="form-control"
                        onChange={(e) => {
                          this.setState({
                              reason: e.target.value
                            })
                          }}  
                        value={this.state.reason}
                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                      />
                      </div>
                    </form>
                  )}
                </SweetAlert> : null
            }
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="card_id"
                      columns={columns}
                      data={this.state.listVerifikasiNPA}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="card_id"
                          columns={columns}
                          data={this.state.listVerifikasiNPA}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <Input
                                    onChange={this.handleselect}
                                    type="select"
                                    id="mySelect"
                                  >
                                    <option value="W">Waiting</option>
                                    <option value="A">Approve</option>
                                    <option value="R">Reject</option>
                                  </Input>
                                  <br />
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"card_id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <br />
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
            {this.state.alert ?
                        <SweetAlert title={this.state.message} onConfirm={this.closeApprove}>
                        </SweetAlert> : ''
                    }
        </div>
      </React.Fragment>
    );
  }
}
export default VerifikasiNPA;
