import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";



class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef(),
      this.state = {
        admin_type: '',
        admin_previlege: [],
        admin: {},
        user: {},
        reward_point: {},
        jurnal_tatalaksana: {},
        ebook: {},
        webinar: {},
        tindakan: {},
        animasi: {},
        acara_ilmiah: {},
        artikel: {},
        cme_online: {},
        tanya_ahli: {},
        master: {},
        file_manajer: {},
        headline: {},
        perixa: {},
      }
  }

  // componentDidMount() {
  //   const { admin_name, admin_type } = localStorage.getItem(JSON.parse('user'))
  //   console.log('admin', admin_type)
  // }

  componentDidMount() {
    this.initMenu()
    const { admin_type, admin_previlege } = JSON.parse(localStorage.getItem('user'))
    // let data = JSON.parse(admin_previlege)
    this.setState({
      admin_type: admin_type,
      admin_previlege: admin_previlege,
      admin: admin_previlege[0],
      user: { ...admin_previlege[1] },
      jurnal_tatalaksana: { ...admin_previlege[2] },
      ebook: { ...admin_previlege[3] },
      webinar: { ...admin_previlege[4] },
      tindakan: { ...admin_previlege[5] },
      animasi: { ...admin_previlege[6] },
      acara_ilmiah: { ...admin_previlege[7] },
      artikel: { ...admin_previlege[8] },
      cme_online: { ...admin_previlege[9] },
      tanya_ahli: { ...admin_previlege[10] },
      file_manajer: { ...admin_previlege[11] },
      master: { ...admin_previlege[12] },
      headline: { ...admin_previlege[13] },
      reward_point: {...this.getAccessRewardPointValue(admin_previlege)},
      perixa: { ...admin_previlege[15] },
    })
  }

  getAccessRewardPointValue = admin_previlege => {
    try {
      let rewardPointAccess = {
        name: "reward-point",
        access: "N"
      };
      
      const indexOfRewardPoint = admin_previlege.findIndex(listMenu => listMenu.name === 'reward-point');

      if(indexOfRewardPoint >= 0) {
        rewardPointAccess = admin_previlege[indexOfRewardPoint];
      }
      return rewardPointAccess;
    } catch(error) {
      console.log("Error while get acces for reward point menu: ", error);
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }

  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    // setTimeout(() => {
    //   if (this.refDiv.current !== null) {
    //     if (item) {
    //       const currentPosition = item.offsetTop;
    //       if (currentPosition > window.innerHeight) {
    //         // if (this.refDiv.current)
    //         //   this.refDiv.current.getScrollElement().scrollTop =
    //         //     currentPosition - 300;
    //       }
    //     }
    //   }
    // }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          {this.state.admin_type == 'organisasi' ? <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>

              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bxs-tachometer" />
                  <span>{this.props.t("Dashboard")}</span>
                </Link>
              </li>

              <li>
                <Link to="/event">
                  <i className="bx bx-calendar-event" />
                  <span>{this.props.t("Acara Ilmiah")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/event" className="">
                      <span>{this.props.t("List Acara")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

            </ul>
          </div> :
            //ini type admin admin
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{this.props.t("Menu")}</li>

                <li>
                  <Link to="/dashboard" className="">
                    <i className="bx bxs-tachometer" />
                    <span>{this.props.t("Dashboard")}</span>
                  </Link>
                </li>

                {/* ADMIN */}
                {this.state.admin.access == 'Y' ?
                  <li>
                    <Link to="/list-admin" className="">
                      <i className="bx bxs-user-circle" />
                      <span>{this.props.t("Admin")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/list-admin">{this.props.t("List User")}</Link>
                      </li>
                      
                      {/* <li>
                    <Link to="/verifikasi-user">{this.props.t("Daftar User")}</Link>
                  </li> */}
                    </ul>
                  </li>
                  : null}

                {/* USER */}
                {this.state.user.access == 'Y' ?
                  <li>
                    <Link to="/#" className="">
                      <i className="bx bxs-user-account" />
                      <span>{this.props.t("User")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      {/* <li>
                        <Link to="/verifikasi-sertifikat">{this.props.t("Verifikasi Sertifikat")}</Link>
                      </li>
                      <li>
                        <Link to="/verifikasi-registrasi-user">{this.props.t("Verifikasi Registrasi")}</Link>
                      </li> */}
                      <li>
                        <Link to="/daftar-user">{this.props.t("Daftar User")}</Link>
                      </li>
                      <li>
                        <Link to="/user/cme">{this.props.t("List User CME")}</Link>
                      </li>
                      {/* <li>
                        <Link to="/user/certificate-regenerator">Regenerate All Certificates</Link>
                      </li> */}
                      <li>
                        <Link to="/user/verifikasi-npa">{this.props.t("List Verifikasi NPA")}</Link>
                      </li>
                      <li>
                        <Link to="/user/verifikasi-str">{this.props.t("List Verifikasi STR")}</Link>
                      </li>
                      <li>
                        <Link to="/hubungi-admin">{this.props.t("List Hubungi Admin")}</Link>
                      </li>
                    </ul>
                  </li>
                  : null}

                {/* PERIXA */}
                {this.state.perixa.access == 'Y' ?
                  <li>
                    <Link to="/perixa" className="">
                      <i className="bx bx-money" />
                      <span>{this.props.t("Perixa")}</span>
                    </Link>
                  </li>
                  : null}

                {/* REWARD POINT */}
                {this.state.reward_point.access == 'Y' ?
                  <li>
                    <Link>
                      <i className="bx bx-money" />
                      <span>{this.props.t("Reward Point")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">                      
                      <li>
                        <Link to="/reward-point-activity">{this.props.t("Reward Point Activity")}</Link>
                      </li>
                    </ul>
                  </li>
                  : null}

                {/* JURNAL & TATA LAKSANA */}
                {this.state.jurnal_tatalaksana.access == 'Y' ?
                  <li>
                    <Link to="/jurnaltatalaksana" className="">
                    <i className="bx bx-book-bookmark"/>
                      <span>{this.props.t("Jurnal & Tata Laksana")}</span>
                    </Link>
                  </li>
                  : null}

                {/* EBOOK */}
                {/* {this.state.ebook.access == 'Y' ?
                  <li>
                    <Link to="/ebook" className="" >
                    <i className='bx bxs-book-content'/>
                      <span>{this.props.t("eBook")}</span>
                    </Link>
                  </li>
                  : null} */}

                {/* WEBINAR */}
                {this.state.webinar.access == 'Y' ?
                  <li>
                    <Link to="/webinar" className="">
                     <i className='bx bx-cast'/>
                      <span>{this.props.t("Webcast")}</span>
                    </Link>
                  </li>
                  : null}

                {/* TINDAKAN MEDIS SPESIALISTIK */}
                {this.state.tindakan.access == 'Y' ?
                  <li>
                    <Link to="/tindakanmedis" className="">
                      <i className='bx bx-plus-medical'/>
                      <span>{this.props.t("Tindakan Medis Spesialistik")}</span>
                    </Link>
                  </li>
                  : null}

                {/* ANIMASI PENGGUNAAN OBAT */}
                {/* {this.state.animasi.access == 'Y' ?
                  <li>
                    <Link to="/animasi-penggunaan-obat" className="">
                      <i className="bx bx-file" />
                      <span>{this.props.t("Petunjuk Penggunaan Obat")}</span>
                    </Link>
                  </li>
                  : null} */}

                {/* ACARA ILMIAH */}
                {this.state.acara_ilmiah.access == 'Y' ?
                  <li>
                    <Link to="/event" className="">
                      <i className='bx bx-calendar-event'/>
                      <span>{this.props.t("Acara Ilmiah")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/event" className="">
                          {this.props.t("List Acara")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/list-event">{this.props.t("Moderasi Acara")}</Link>
                      </li>
                    </ul>
                  </li>
                  : null}
                
                {/* ACARA ILMIAH INTERNASIONAL */}
                {this.state.acara_ilmiah.access == 'Y' ?
                  <li>
                    <Link to="/event/internasional" className="">
                      <i className='bx bx-calendar-event' />
                      <span>Acara Ilmiah Internasional</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/event/internasional" className="">
                          List Acara Internasional
                        </Link>
                      </li>
                      <li>
                        <Link to="/list-event/internasional">
                          Moderasi Acara Internasional
                        </Link>
                      </li>
                    </ul>
                  </li>
                  : null}

                {/* ARTIKEL */}
                {this.state.artikel.access == 'Y' ?
                  <li>
                    <Link to="/artikel" className="">
                      <i className='bx bx-pencil'/>
                      <span>{this.props.t("Artikel")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/artikel">
                          <span>{this.props.t("List Artikel")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/verifikasi-artikel">{this.props.t("Moderasi Artikel")}</Link>
                      </li>
                      <li>
                        <Link to="/artikel/tambahartikeluser">{this.props.t("Tambah Artikel User")}</Link>
                      </li>
                    </ul>
                  </li>
                  : null}

                {/* CME ONLINE */}
                {/* <li>
                  <Link to="/#">
                    <i className="bx bx-file" />
                    <span>{this.props.t("CME Online")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/cme-online">
                        <span>{this.props.t("List Artikel")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/verifikasi-artikel">{this.props.t("Moderasi Artikel")}</Link>
                    </li>
                  </ul>
                </li> */}

                {/* CME ONLINE */}
                {this.state.cme_online.access == 'Y' ?
                  <li>
                    <Link to="/cme-online" className="">
                      <i className='bx bx-spreadsheet'/>
                      <span>{this.props.t("CME Online")}</span>
                    </Link>
                  </li>
                  : null}
                {/* CME ONLINE */}

                {/* TANYA AHLI */}
                {this.state.tanya_ahli.access == 'Y' ?
                  <li>
                    <Link to="/tanya-ahli" className="">
                      <i className='bx bx-message-rounded'/>
                      <span>{this.props.t("Tanya Ahli")}</span>
                    </Link>
                  </li>
                  : null}

                {/* MASTER */}
                {this.state.master.access == 'Y' ?
                  <li>
                    <Link to="/master-organizer" className="">
                      <i className='bx bx-data'/>
                      <span>{this.props.t("Master")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/master-organizer" className="">
                          <span>{this.props.t("Organisasi")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/master-penulis">{this.props.t("Penulis/Pembicara")}</Link>
                      </li>
                      <li>
                        <Link to="/master-penyakit">{this.props.t("Penyakit")}</Link>
                      </li>
                      <li>
                        <Link to="/master-molekul">{this.props.t("Molekul")}</Link>
                      </li>
                      {/* <li>
                        <Link to="/master-seogeneral">{this.props.t("SEO General")}</Link>
                      </li> */}
                      <li>
                        <Link to="/list-seogeneral">{this.props.t("List SEO General")}</Link>
                      </li>
                    </ul>
                  </li>
                  : null}

                {/* FILE MANAJER */}
                {this.state.file_manajer.access == 'Y' ?
                  <li>
                    <Link to="/filemanager" className="">
                      <i className="bx bx-file" />
                      <span>{this.props.t("File Manajer")}</span>
                    </Link>
                  </li>
                  : null}

                {/* HEADLINE */}
                {this.state.headline.access == 'Y' ?
                  <li>
                    <Link to="/handling-cms" className="">
                    <i className='bx bx-heading'/>
                      <span>{this.props.t("Headline")}</span>
                    </Link>
                  </li>
                  : null}

              </ul>
            </div>
            }
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
