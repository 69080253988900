import React, { Component } from "react"

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import SweetAlert from 'react-bootstrap-sweetalert';
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { withRouter } from "react-router-dom"

import axios from 'axios'
import moment from 'moment'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Master/datatables.scss";

import httprequester from '../../lib/httprequester'
import { urlAPI } from "../../config";

class CertificateRegenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 10,
            ListUser: [],
            loading: false,
            sertifikatPopup: false,
            imageSertifikat: '',
            generateSertifikatPopUp: false,
            bodySertifikat: {},
            alert: false,
            message: '',
            isGenerating: false,
            currentlyGeneratedCert: {},
            generationCounter: 1,
        }
    }

    componentDidMount() {
      this.getRegenerateeCert();
    }

    popUpSertifikat = (row) => {
        this.setState({
            imageSertifikat: row.cme_sertifikat,
            sertifikatPopup: true
        })
    }

    popUpGenerateSertifikat = () => {
      this.setState({ generateSertifikatPopUp: true });
    }

    getRegenerateeCert = () => {
        // this.setState({ loading: true })

        httprequester.get('sync/regenerated-certificates', {
            success: async function (response) {
                this.setState({
                    ListUser: response.data.data,
                    loading: false,
                })
            }.bind(this),
            error: function (err) {}.bind(this)
        })
    }

    generateAllSertifikat = async () => {
      this.setState({ generateSertifikatPopUp: false, isGenerating: true });

      for (const cert of this.state.ListUser) {
        this.setState({ currentlyGeneratedCert: cert });

        try {
          const response = await axios.post(
            urlAPI + 'cme/regen-overwrite-certificate',
            cert,
            { headers: { Authorization: 'Bearer ' + httprequester.get_token() }}
          );

          if (response.data.status === 'success') {
            this.setState(
              (prevState, props) => ({ generationCounter: prevState.generationCounter + 1 })
            );
          } else {
            this.setState({
              alert: true,
              message: response.data.detailMessage, 
            });
          }
        } catch (error) {
          this.setState({
            alert: true,
            message: error.message,
          });
        }
      }

      this.setState({ isGenerating: false, generationCounter: 1 });
    }

    render() {
        const columns = [
            {
                dataField: 'user_id',
                text: 'User ID',
                hidden: true
            },
            {
                dataField: 'user_name',
                text: 'Nama User',
                sort: true
            },
            {
                dataField: 'npa_name',
                text: 'Nama NPA',
                sort: true
            },
            {
                dataField: 'cme_name',
                text: 'Judul CME',
                sort: true
            },
            {
                dataField: 'cme_score',
                text: 'Score CME',
                sort: true
            },
            {
                dataField: 'issued_certificate_date',
                text: 'Certificate Date',
                sort: true,
                formatter: (row) => {
                  let date = row;
                  if (row !== '-') {
                    date = moment(row).format("YYYY-MM-DD HH:mm:ss");
                  }

                  return <div>{date}</div>
                }
            },
            {
                dataField: 'cme_ol_id',
                editable: false,
                sort: false,
                formatter: (v, row) => {
                  const certUrl = row.cme_sertifikat
                    ? row.cme_sertifikat.replace("http:", "https:")
                    : row.cme_sertifikat;

                  if (row.cme_score >= 60) {
                    return (
                      <div>
                        <a className="btn-sm btn-success" rel="noreferrer" target="blank" href={certUrl}>
                          Lihat Sertifikat
                        </a>
                      </div>
                    )
                  }

                  return null;
                }
            }];

        const defaultSorted = [{
            dataField: 'cme_score',
            order: 'desc'
        }];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.ListUser.length, // replace later with size(customers),
            custom: true,
        }

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                      <title>Certificate Regenerator | Ruang Ilmiah</title>
                    </MetaTags>
                    <Container fluid>
                      <Breadcrumbs title="Halaman Utama" breadcrumbItem="Certificate Regenerator" />
                    </Container>
                    <div className="container-fluid">
                        {
                          this.state.sertifikatPopup
                          ? <SweetAlert
                                title="Sertifikat CME"
                                onCancel={() => this.setState({ sertifikatPopup: false })}
                                showCancel
                                showConfirm={false}
                                style={{ width: '70em' }}
                            >
                              {
                                renderProps => (
                                  <form>
                                    <img src={this.state.imageSertifikat} width="100%" />
                                  </form>
                                )
                              }
                            </SweetAlert>
                          : null
                        }

                        {
                          this.state.generateSertifikatPopUp
                            ? <SweetAlert
                                title="Buat Ulang Sertifikat CME"
                                onConfirm={this.generateAllSertifikat}
                                onCancel={() => this.setState({ generateSertifikatPopUp: false })}
                                showCancel
                                showConfirm
                              >
                                {(renderProps) => <p>Apakah Anda yakin membuat ulang semua sertifikat?</p>}
                              </SweetAlert>
                            : null
                        }
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='cme_ol_id'
                                            columns={columns}
                                            data={this.state.ListUser}
                                        >
                                          {
                                            ({ paginationProps, paginationTableProps }) => (
                                              <ToolkitProvider
                                                keyField='cme_ol_id'
                                                columns={columns}
                                                data={this.state.ListUser}
                                                search
                                              >
                                                {
                                                  toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2 d-flex justify-content-between">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="2">
                                                              <Button
                                                                color="danger"
                                                                onClick={this.popUpGenerateSertifikat}
                                                                disabled={this.state.isGenerating}
                                                              >
                                                                Regenerate All Certificate
                                                              </Button>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField="cme_ol_id"
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes= "table align-middle table-nowrap"
                                                                        headerWrapperClasses="thead-light"
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone {...paginationProps} />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone {...paginationProps} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                  )
                                                }
                                              </ToolkitProvider>
                                            )
                                          }
                                        </PaginationProvider>

                                        {
                                          this.state.isGenerating
                                            ? <p>
                                                Generating {this.state.currentlyGeneratedCert.cme_name} for {this.state.currentlyGeneratedCert.npa_name} ({this.state.generationCounter}/{this.state.ListUser.length})
                                              </p>
                                            : null
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {
                      this.state.alert
                        ? <SweetAlert title={this.state.message} onConfirm={() => this.setState({ alert: false })} />
                        : ''
                    }
                    {
                      this.state.loading
                        ? <SweetAlert title="menghubungkan ke server" showConfirm={false} />
                        : null
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(CertificateRegenerator);