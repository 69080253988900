import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { CSVLink, CSVDownload } from "react-csv";
import BootstrapTable from 'react-bootstrap-table-next';
import ReactHtmlParser from 'react-html-parser';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';
import Kategori from "pages/KategoriTags/kategori";
import moment from "moment";

class ListPercakapan extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
        this.state = {
            basic: false,
            listPercakapan: [],
            listPeriode:[],
            listType: [
                {
                    nama: 'Artikel',
                    value: 'artikel'
                },
                {
                    nama: 'Webcast',
                    value: 'webcast'
                },
                {
                    nama: 'Tindakan Medis Spesialistik',
                    value: 'tindakan'
                },
                {
                    nama: 'Jurnal',
                    value: 'jurnal'
                },
                {
                    nama: 'Tata Laksana',
                    value: 'tata'
                }
            ],
            tanya_topik: '',
            tanya_content_id:'',
            tanya_narasumber: '',
            tanya_type: [],
            tanya_penyakit: '',
            tanya_spesialisai:'',
            tanya_summary:'',
            tanya_thumbnail:'',
            tanya_summary:'',
            tanya_kuota:'',
            tanya_periode_start:'',
            tanya_periode_end: '',
            periode_id:0,
            quota:0,
            // hasilSpesialisasi:'',
            // hasilPenyakit:'',
            prosess: false,
            formEditQuota:false,
            dataCSV:[]
        };
    }

   async componentDidMount() {
      await  this.getListTanyaAhli(),
          await this.getListPercakapan()
       await this.getContentPercakapan()
        // this.getListTanyaAhliPeriode()
    }

    getListPercakapan = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            "tanya_id": routerSplitter[5],
            "page": 1,
            "limit": 1000,
            "order": "DESC"
        }

        httprequester.postarticle('ahli/list-peserta', body ,{
            success: async function (response) {

                // console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    let data = []

                    response.data.detailMessage.data.forEach(async (v, key) => {
                        await data.push({
                            periode_id: v.periode_id,
                            created_at: moment(v.created_at).format('DD MMM YYYY'),
                            tanya_conv_text: ReactHtmlParser(v.tanya_conv_text),
                            tanya_conv_role: v.tanya_conv_role,
                            tanya_conv_status: v.tanya_conv_status,
                            tanya_id: v.tanya_id,
                            content_title: v.content_title,
                            user_id:v.user_id,
                            url: v.url,
                            tanya_name: v.tanya_name
                        })
                    })
                    this.setState({
                        listPercakapan: data
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.setState({
            basic:false,
            tanya_kuota:'',
            tanya_periode_start:'',
            tanya_periode_end:''
        })
        this.getListTanyaAhli()
        // this.getListTanyaAhliPeriode()
    }

 

    clearKonten = () => {
        this.setState({
            basic: false,
            editorState: EditorState.createEmpty(),
            jurnalJudul: '',
            jurnalPenulis: [],
            jurnalKonten: '',
            jurnalGambar: '',
            jurnalSpesialis: [],
            jurnalMolekul: [],
            jurnalPenyakit: []
        });
    }

    getListTanyaAhliPeriode = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            tanya_id: routerSplitter[5]
        }

        httprequester.postarticle('ahli/get-periode', body ,{
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                if (response.data.message == "success") {
                    let data = []
                    response.data.detailMessage.forEach(async(v,key) => {
                        await data.push({
                            periode_id: v.periode_id,
                            periode_start: moment(v.periode_start).format('DD MMM YYYY'),
                            periode_end: moment(v.periode_end).format('DD MMM YYYY'),
                            quota: v.quota,
                            quota_left: v.quota_left,
                            narasumber_id: v.narasumber_id
                        })
                    })
                    this.setState({
                        listPeriode: data
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }


    getListTanyaAhli = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "tanya_id": routerSplitter[5]
        }

        // console.log("body =>", body)
        httprequester.postarticle('ahli/get-detail', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                if (response.data.message == "success") {
                    this.setState({
                        tanya_topik: response.data.detailMessage.datarows.length > 0 ?  response.data.detailMessage.datarows[0][15] : '',
                        tanya_periode_start: response.data.detailMessage.datarows.length > 0 ? moment(response.data.detailMessage.datarows[0][3]).format('DD MMM YYYY') : '',
                        tanya_kuota: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][1] : '',
                        tanya_periode_end: response.data.detailMessage.datarows.length > 0 ? moment(response.data.detailMessage.datarows[0][11]).format('DD MMM YYYY') : ''
                    })   
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveTanyaPeriode = () => {

        let router = window.location.href
        let routerSplitter = router.split("/");

        // console.log('start', this.state.tanya_periode_start);
        // console.log('end', this.state.tanya_periode_end);
        let hasil = this.state.listNarasumber.find(k => k.narasumber_name == this.state.tanya_narasumber)  
        // console.log('id', hasil.narasumber_id);
        

        let body = {
            tanya_id: routerSplitter[5],
            tanya_periode_start:moment(this.state.tanya_periode_start).format('YYYY-MM-DD'),
            tanya_periode_end:moment(this.state.tanya_periode_end).format('YYYY-MM-DD'),
            tanya_kuota:this.state.tanya_kuota,
            tanya_status: routerSplitter[7],
            tanya_type: routerSplitter[6],
            narasumber_id:hasil.narasumber_id,
            periode_id:this.state.periode_id
        }
        // console.log("body =>", body)
        httprequester.postarticle('ahli/save-detail', body, {
            success: async function (response) {
                // console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    save = () => {
        let slugLower = this.state.jurnalJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let formData = new FormData();
        formData.append("myfile", this.state.file);
        formData.append("jurnal_thumbnail", this.state.jurnalGambar);
        formData.append("jurnal_title", this.state.jurnalJudul);
        formData.append("jurnal_slug", slugRep);
        formData.append("jurnal_penulis", this.state.jurnalPenulis.toString());
        formData.append("jurnal_penyakit", this.state.jurnalPenyakit.toString());
        formData.append("jurnal_spesialis", this.state.jurnalSpesialis.toString());
        formData.append("jurnal_molekul", this.state.jurnalMolekul.toString());
        formData.append("jurnal_content", this.state.jurnalKonten)
        formData.append("jurnal_type", this.state.type)

        console.log("formData => ", formData);

        this.setState({
            prosess: true
        })
        httprequester.postupload('jurnal/save', formData, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        prosess: false
                    })
                    this.fileUploads.current
                    // e.target.value = null;
                    // this.fileUploads.current.value
                    // this.getFileUpload()
                }
                // console.log('ini', this.fileUploads)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    directDetail = (row) => {      
        this.props.history.push(`/tanya-ahli/detail/${row.tanya_id}/${row.user_id}`)
    }

    directSEO = () => {
        let url = localStorage.getItem('url')
        let hasil = url.split('/')
        // console.log('hasil',hasil);
        this.props.history.push(`/${hasil[3]}/${hasil[4]}/${hasil[5]}/${hasil[6]}/${hasil[7]}`)
    }

    directQna = (row) => {      
        this.props.history.push(`/tanya-ahli/qna/${row.tanya_id}/${row.user_id}`)
    }

    getContentPercakapan = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            "tanya_id": routerSplitter[5],
            // "user_id": routerSplitter[6],
            // "limit": 10000
        }

        httprequester.postarticle('ahli/list-content-percakapan', body, {
            success: async function (response) {

                // console.log("resLOV => ", response.data.detailMessage.data[0])
                if (response.data.message == "success") {
                    let data = response.data.detailMessage.filter(a => a.tanya_conv_status != '4')
                    // console.log('data', data);
                    // this.setState({
                    //     ListDetailPercakapan: data
                    // })

                    let args = [];
                    data.map((v, i) => {
                        let obj = {
                            nama: v.tanya_name,
                            percakapan: v.tanya_conv_text,
                            status_jawaban: v.tanya_conv_status == '1' ? 'belum dijawab' : v.tanya_conv_status == '2' ? 'sudah dijawab' : v.tanya_conv_status == '3' ? 'tidak perlu dijawab': '',
                            file: v.tanya_conv_attachment,
                            role: v.tanya_conv_role,
                            topik: this.state.tanya_topik,
                            kuota: this.state.tanya_kuota
                        }
                        args.push(obj)
                    })

                    this.setState({
                        dataCSV: args
                    })

                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    submitNPA = () => {
        let body = {
            npa_id,
            npa_name,
            npa_no,
            user_id
        }

        // console.log(body)
        this.setState({
            loading: true,
            formEditQuota: false
        })

        httprequester.postnobody('admin/npa', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        message: response.data.detailMessage,
                        alert: true,
                        loading: false,
                    })
                    // this.getUser()
                }
                // console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    render() {
        const columns = [{
            dataField: 'tanya_name',
            text: 'Nama',
            sort: true
        }, {
                dataField: 'tanya_conv_text',
                text: 'Percakapan',
                sort: true
            },
            {
                dataField: 'created_at',
                text: 'Tanggal Percakapan',
                sort: true
            },
            {
            dataField: 'periode_id',
            text: 'Action',
            sort: true,
                formatter: (cell, row, index) => {
                    console.log(cell, row, index)
                // let data = this.state.listPeriode.find(j => j.periode_id == row)
                return <div>
                    <Button
                        color="success"
                        className="btn-sm"
                        onClick={(e) => { this.directDetail(row) }}>
                        Detail
                    </Button> 
                    &nbsp;
                    <Button
                        color="primary"
                        className="btn-sm"
                        onClick={(e) => { this.directQna(row) }}>
                        
                        Q&A
                    </Button> 
                </div>
            }
        }
    ];

        const defaultSorted = [{
            dataField: 'periode_id',
            order: 'asc'
        }];

        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.state.listPeriode.length, // replace later with size(customers),
            custom: true,
        }

        const { SearchBar } = Search;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>List Percakapan</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tanya Ahli" breadcrumbItem="List Percakapan"  />

                        <Row>
                            <Col lg={12}>
                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO() }}> Kembali</button>
                                <br />
                                <br />
                                <Card>
                                    <CardBody>
                                        <Form id="form-periode">
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Nama Tanya Ahli : {this.state.tanya_topik} </Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Periode : {this.state.tanya_periode_start} - {this.state.tanya_periode_end}</Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Quota : {this.state.tanya_kuota}</Label>
                                            </div>
                                        </Form>
                                        <Button
                                            color="success"
                                            className="btn-sm"
                                        >
                                            <CSVLink data={this.state.dataCSV} style={{ color: 'white' }}>Download CSV</CSVLink>
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12" md="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField='periode_id'
                                            columns={columns}
                                            data={this.state.listPercakapan}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField='periode_id'
                                                    columns={columns}
                                                    data={this.state.listPercakapan}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"certificate_name"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )
                                                    }
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                       
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default ListPercakapan;
