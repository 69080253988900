import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from '@tinymce/tinymce-react';

class Artikel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic: false,
            // editorState: EditorState.createEmpty(),
            // editorState: EditorState.createWithContent(
            //     ContentState.createFromBlockArray(
            //       convertFromHTML('<p>My initial content.</p>')
            //     )
            //   ),
            listCategory: [],
            listSpesialis: [],
            listMolekul: [],
            listPenyakit: [],
            listPenulis: [],
            articleJudul: '',
            articlePenulis: [],
            articlePenyakit: [],
            articleMolekul: [],
            articleSpesialis: [],
            articleKonten: '',
            articleGambar: '',
            deskripsi: null,
            summary: '',
            prosess: false
        };
    }

    componentDidMount() {
        this.getCategory();
        this.getLOV();
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/artikel`)
    }

    handleEditorChange = (e) => {
        // console.log('as', e.target.getContent())
        this.setState({
            deskripsi: e.target.getContent()
        })
    }

    clearKonten = () => {
        this.setState({
            basic: false,
            // editorState: EditorState.createEmpty(),
            articleJudul: '',
            articlePenulis: [],
            articleKonten: '',
            articleGambar: '',
            articleSpesialis: [],
            articleMolekul: [],
            articlePenyakit: []
        });
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                // console.log("resLOV => ", response.data.detailPenyakit)
                if (response.data.message == "success") {
                    let datapenyakit = []
                    response.data.detailPenyakit.forEach((v, key) => {
                        // console.log('v',v);
                        datapenyakit.push({
                            penyakit_id: v.penyakit_id,
                            penyakit_name: v.penyakit_name == null ? '-' : v.penyakit_name
                        })
                    })

                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                        listMolekul: response.data.detailMolekul,
                        listPenyakit: datapenyakit,
                        listPenulis: response.data.detailPenulis
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveArticle = () => {
        let slugLower = this.state.articleJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');

        let body = {
            "article_title": this.state.articleJudul,
            "article_slug": slugRep,
            "article_thumbnail": this.state.articleGambar,
            "article_penulis": this.state.articlePenulis.toString(),
            "article_penyakit": this.state.articlePenyakit.toString(),
            "article_molekul": this.state.articleMolekul.toString(),
            "article_spesialis": this.state.articleSpesialis.toString(),
            "article_status": "Unpublished",
            "article_content": this.state.deskripsi,
            "article_summary": this.state.summary
        }
        console.log("body =>", body)
        this.setState({
            prosess: true
        })
        httprequester.postarticle('artikel/save', body, {
            success: async function (response) {
                console.log("res => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        prosess: false
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handleChangePenulis(e) {
        this.setState({
            // articlePenulis: e
            articlePenulis: e
        })
        // console.log("INIT N", this.state.articlePenulis);
        // console.log("INIT N1", e[0].penulis_name);
    }

    handleChangeSpesialis(e) {

        // if(e[0].hasOwnProperty('specialist_name')){
        //     console.log("A")
        // }else if(e[0].hasOwnProperty('customOption')) {
        //     console.log("B")
        // }else{
        //     console.log("C")
        // }

        this.setState({
            // articleSpesialis: e
            articleSpesialis: e
        })
        console.log("INIT S", e);
        // console.log("INIT S1", e[0].specialist_name);
    }

    handleChangeMolekul(e) {
        this.setState({
            // articleMolekul: e
            articleMolekul: e
        })
        console.log("INIT M", e);
        // console.log("INIT M1", e[0].molekul_name);
    }

    handleChangePenyakit = (e) => {
        this.setState({
            // articleMolekul: e
            articlePenyakit: e
        })
        console.log("INIT P", e);
        // console.log("INIT M1", e[0].molekul_name);
    }

    // handleChangePenyakit(e) {
    //     this.setState({
    //         // articlePenyakit: e
    //         articlePenyakit: e
    //     })
    //     console.log("INIT P", e);
    //     // console.log("INIT P1", e[0].penyakit_name);
    // }

    render() {

        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionMolekul = this.state.listMolekul.map(o => o.molekul_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.penyakit_name)
        const optionPenulis = this.state.listPenulis.map(o => o.penulis_name)

        // console.log("hacep => ", optionPenulis)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tambah Artikel</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Artikel" breadcrumbItem="Tambah Artikel" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Artikel berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        {/* <CardTitle className="mb-4">Form Grid layout</CardTitle> */}

                                        <Form>
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Judul Artikel</Label>
                                                <Input
                                                    id="artikelJudul"
                                                    type="input"
                                                    placeholder="Input Judul Artikel"
                                                    onChange={(e) => this.setState({ articleJudul: e.target.value })}
                                                    value={this.state.articleJudul}
                                                />
                                            </div>

                                            {/* <div className={this.state.col}>
                                                <div className="form-group">
                                                    <label>Spesialis</label>
                                                    <Typeahead
                                                        labelKey={option => `${option.category_name}`}
                                                        options={[...this.state.listCategory]}
                                                        onChange={(e) => this.handleChangeSpesialis(e)}
                                                    />
                                                </div>
                                            </div> */}

                                            {/* <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Kategori</Label>
                                                        <Input
                                                            type="select"
                                                            name="select"
                                                            id="select"
                                                            onChange={(e) => this.setState({ articleCategory: e.target.value })}
                                                            value={this.state.articleCategory}
                                                        >
                                                            <option value="">Pilih Kategori</option>
                                                            {this.state.listCategory ? this.state.listCategory.map((cat) => (
                                                                <option key={cat.category_id} value={cat.category_name}>{cat.category_name}</option>
                                                            )) : null}
                                                        </Input>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">Tags</Label>
                                                        <Input
                                                            type="email"
                                                            className="form-control"
                                                            id="horizontal-email-Input"
                                                            placeholder="Input Tags"
                                                            disabled
                                                        />
                                                    </div>
                                                </Col>
                                            </Row> */}

                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Penulis</Label>
                                                        <Typeahead
                                                            // allowNew
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penulis"
                                                            labelKey="name"
                                                            options={optionPenulis}
                                                            onChange={(e) => this.handleChangePenulis(e)}
                                                            selected={this.state.articlePenulis}
                                                        />
                                                        {/* <Input
                                                            type="input"
                                                            placeholder="Input Nama Penulis"
                                                            onChange={(e) => this.setState({ articlePenulis: e.target.value })}
                                                            value={this.state.articlePenulis}
                                                        /> */}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-email-Input">URL Gambar Thumbnail</Label>
                                                        <Input
                                                            id="artikelThumbnail"
                                                            type="input"
                                                            placeholder="Input Link Thumbnail"
                                                            onChange={(e) => this.setState({ articleGambar: e.target.value })}
                                                            value={this.state.articleGambar}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputCity">Spesialis</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Spesialis"
                                                            labelKey="name"
                                                            options={option}
                                                            onChange={(e) => this.handleChangeSpesialis(e)}
                                                            selected={this.state.articleSpesialis}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputState">Molekul</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Molekul"
                                                            labelKey="name"
                                                            options={optionMolekul}
                                                            onChange={(e) => this.handleChangeMolekul(e)}
                                                            selected={this.state.articleMolekul}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputZip">Penyakit</Label>
                                                        <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penyakit"
                                                            labelKey="name"
                                                            options={optionPenyakit}
                                                            onChange={(e) => this.handleChangePenyakit(e)}
                                                            selected={this.state.articlePenyakit}
                                                        />
                                                        {/* <Typeahead
                                                            id="basic-typeahead-multiple"
                                                            multiple
                                                            placeholder="Pilih Penyakit"
                                                            labelKey="name"
                                                            options={optionPenyakit}
                                                            onChange={(e) => this.handleChangePenyakit(e)}
                                                            value={this.state.articlePenyakit}
                                                        /> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-InputCity">Summary</Label>
                                                        <Input type="textarea" onChange={(e) => this.setState({ summary: e.target.value })} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>

                            {/* <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-firstname-Input"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Kategori
                                                </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="select"
                                                        name="select"
                                                        id="select"
                                                        onChange={(e) => this.setState({ articleCategory: e.target.value })}
                                                        value={this.state.articleCategory}
                                                    >
                                                        <option value="">Choose Category</option>
                                                        {this.state.listCategory ? this.state.listCategory.map((cat) => (
                                                            <option key={cat.category_id} value={cat.category_name}>{cat.category_name}</option>
                                                        )) : null}
                                                    </Input>
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-email-Input"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Tags
                                                </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="email"
                                                        className="form-control"
                                                        id="horizontal-email-Input"
                                                        placeholder="Enter Tags"
                                                        disabled
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-password-Input"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Judul Artikel
                                                </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="input"
                                                        placeholder="Input Judul Artikel"
                                                        onChange={(e) => this.setState({ articleJudul: e.target.value })}
                                                        value={this.state.articleJudul}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-password-Input"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Penulis
                                                </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="input"
                                                        placeholder="Input Nama Penulis"
                                                        onChange={(e) => this.setState({ articlePenulis: e.target.value })}
                                                        value={this.state.articlePenulis}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="row mb-4">
                                                <Label
                                                    htmlFor="horizontal-password-Input"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    Gambar Thumbnail
                                                </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="input"
                                                        placeholder="Input Link Thumbnail"
                                                        onChange={(e) => this.setState({ articleGambar: e.target.value })}
                                                        value={this.state.articleGambar}
                                                    />
                                                </Col>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <CardBody>
                                        <Form method="post">
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                content={this.state.deskripsi}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                        'removeformat link',
                                                    content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                                    line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                                    // images_upload_handler: function (blobInfo, success, failure) {
                                                    //     let data = new FormData();
                                                    //     data.append('myfile', blobInfo.blob(), blobInfo.filename());
                                                    //     console.log(data)
                                                    //     axios.post('http://127.0.0.1:3333/api/v1/filemanager/uploadtest', data)
                                                    //         .then(function (res) {
                                                    //             success(res.data.detailMessage);
                                                    //         })
                                                    //         .catch(function (err) {
                                                    //             console.log(err)
                                                    //             failure('HTTP Error: ' + err.message);
                                                    //         });
                                                    // }
                                                }}
                                                onChange={this.handleEditorChange}
                                            />
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.saveArticle()}>
                                        Submit Artikel
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row md="10" style={{ paddingTop: 8, justifyContent: "end", alignItems: "end" }}>
                            <Button color="danger" onClick={() => this.saveArticle()}>
                                Submit Artikel
                            </Button>
                        </Row> */}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default Artikel;
