import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import moment from "moment";

import { GetIndexFromSchema } from '../../helpers/CommonHelper';

class EditCME extends Component {
    constructor(props) {
        super(props);
        this.fileUploads = createRef();
        this.state = {
            basic: false,
            listSpesialis: [],
            headerCME: [],
            cmeJudul: '',
            cmeSKP: '',
            cmeDeskripsi: '',
            cmeSKPBIDI: '',
            cmeSpesialis: '',
            cmeSpesialis1:'',
            cmeSpesialis2: '',
            cmeOrganisasi:'',
            cmeStatus: '',
            cmeID: '',
            cmeSpc: false,
            cmeSpc1: false,
            cmeSpc2: false,
            cmeOrg:false,
            cmeFilePDF: null,
            cmeFileGambar: null,
            cmeFileVideo: null,
            file: null,
            prosess: false,
            cmescore:'',
            listCME:[],
            basicduplicate:false,
            listOrganisasi:[],
            tanggal:'',
            tanggalAkhir:'',
            cmeProductName1: '',
            cmeProductUrl1: '',
            cmeProductName2: '',
            cmeProductUrl2: '',
        };
    }

    componentDidMount() {
        this.getLOV();
        this.getHeaderCME();
        this.getListCME()
        this.getOrganisasi()
        this.getHeaderCMEMysql()
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    getHeaderCMEMysql = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "cme_id": routerSplitter[5],
        }

        // console.log("bodyreq cme => ", body)
        httprequester.postnobody('cme/cme-header-list', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    console.log("response cme => ", response.data.detailMessage);

                    this.setState({
                        tanggal: response.data.detailMessage.length > 0 ? moment(response.data.detailMessage[0].periode_awal).format('YYYY-MM-DD') : '',
                        tanggalAkhir: response.data.detailMessage.length > 0 ? moment(response.data.detailMessage[0].periode_akhir).format('YYYY-MM-DD') : ''
                    })


                 
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    getOrganisasi = () => {
        let body = {
            "admin_type": "organisasi"
        }

        httprequester.postnobody('organisasi/getAll', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.setState({
                        listOrganisasi: response.data.detailMessage
                    })
                }
                // console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    getListCME = () => {

        let body = {
            "spesialisasi": "",
            "search": "",
            "status": 'Published',
            "page": 1,
            "limit": 10,
            "urutan": "cme_title ASC"
        }

        // console.log("bodyreq cme => ", body)
        httprequester.postnobody('cme/get_header_adm', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    // console.log("response cme => ", response);

                    let data = []
                    response.data.result.data.dataRows.forEach(async (v, key) => {
                        await data.push({
                            cme_id: v[GetIndexFromSchema('cme_id', response.data.result.data.schema)],
                            cme_penulis: v[GetIndexFromSchema('cme_penulis', response.data.result.data.schema)],
                            cme_title: v[GetIndexFromSchema('cme_title', response.data.result.data.schema)],
                            cme_content: v[GetIndexFromSchema('cme_content', response.data.result.data.schema)],
                            created_at: v[GetIndexFromSchema('created_at', response.data.result.data.schema)],
                            cme_slug: v[GetIndexFromSchema('cme_slug', response.data.result.data.schema)],
                            cme_penyakit: v[GetIndexFromSchema('cme_penyakit', response.data.result.data.schema)],
                            cme_skp: v[GetIndexFromSchema('cme_skp', response.data.result.data.schema)],
                            cme_molekul: v[GetIndexFromSchema('cme_molekul', response.data.result.data.schema)],
                            cme_spesialis: v[GetIndexFromSchema('cme_spesialis', response.data.result.data.schema)],
                            cme_status: v[GetIndexFromSchema('cme_status', response.data.result.data.schema)],
                            cme_pdf: v[GetIndexFromSchema('cme_pdf', response.data.result.data.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.result.data.schema)],
                            cme_thumbnail: v[GetIndexFromSchema('cme_thumbnail', response.data.result.data.schema)],
                            cme_no_idi: v[GetIndexFromSchema('cme_no_idi', response.data.result.data.schema)],
                        });
                    })

                    // console.log("dataresp cme => ", data);
                    this.setState({
                        listCME: data
                    })
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    

    back = () => {
        this.props.history.push(`/cme-online`)
    }

    clearKonten = () => {
        this.setState({
            basic: false,
            cmeJudul: '',
            cmeSKP: '',
            cmeDeskripsi: '',
            cmeSKPBIDI: '',
            cmeSpesialis: '',
            cmeSpesialis1: '',
            cmeSpesialis2: '',
            cmeOrganisasi:'',
            cmeStatus: '',
            cmescore: ''
        });
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                // console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getHeaderCME = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        // console.log("routersplit => ", routerSplitter[5]);
        // this.setState({
        //     cmeID: routerSplitter[5]
        // })

        let body = {
            "cme_id": routerSplitter[5],
        }

        // console.log("bodyreq cme => ", body)
        httprequester.postnobody('cme/get_detail', body, {
            success: async function (response) {
                if (response.data.message == "success") {

                    // console.log("response cme => ", response.data.header.datarows);

                    let data = []

                    response.data.header.datarows.forEach(async (v, key) => {
                        await data.push({
                            cme_id: v[GetIndexFromSchema('cme_id', response.data.header.schema)],
                            cme_penulis: v[GetIndexFromSchema('cme_penulis', response.data.header.schema)],
                            cme_title: v[GetIndexFromSchema('cme_title', response.data.header.schema)],
                            cme_content: v[GetIndexFromSchema('cme_content', response.data.header.schema)],
                            created_at: v[GetIndexFromSchema('created_at', response.data.header.schema)],
                            cme_slug: v[GetIndexFromSchema('cme_slug', response.data.header.schema)],
                            cme_penyakit: v[GetIndexFromSchema('cme_penyakit', response.data.header.schema)],
                            cme_skp: v[GetIndexFromSchema('cme_skp', response.data.header.schema)],
                            cme_molekul: v[GetIndexFromSchema('cme_molekul', response.data.header.schema)],
                            cme_spesialis: v[GetIndexFromSchema('cme_spesialis', response.data.header.schema)],
                            cme_status: v[GetIndexFromSchema('cme_status', response.data.header.schema)],
                            cme_pdf: v[GetIndexFromSchema('cme_pdf', response.data.header.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.header.schema)],
                            cme_thumbnail: v[GetIndexFromSchema('cme_thumbnail', response.data.header.schema)],
                            cme_no_idi: v[GetIndexFromSchema('cme_no_idi', response.data.header.schema)],
                            cme_product_name_1: v[GetIndexFromSchema('cme_product_name_1', response.data.header.schema)],
                            cme_product_url_1: v[GetIndexFromSchema('cme_product_url_1', response.data.header.schema)],
                            cme_product_name_2: v[GetIndexFromSchema('cme_product_name_2', response.data.header.schema)],
                            cme_product_url_2: v[GetIndexFromSchema('cme_product_url_2', response.data.header.schema)],
                        });
                    })

                

                    let edit = data.find(o => o.cme_id == routerSplitter[5])

                    // console.log("1 cme => ", edit);
                    // console.log("2 cme => ", data);
                    // console.log("3 cme => ", edit.cme_id);

                    this.setState({
                        headerCME: data,
                        cmeID: edit.cme_id,
                        cmeJudul: edit.cme_title,
                        cmeSKP: edit.cme_skp,
                        cmeDeskripsi: edit.cme_content,
                        cmeSKPBIDI: edit.cme_no_idi,
                        cmeSpesialis: edit.cme_spesialis,
                        cmeSpesialis1: edit.cme_molekul,
                        cmeSpesialis2: edit.cme_penyakit,
                        cmeOrganisasi: edit.cme_penulis,
                        cmeStatus: edit.cme_status,
                        cmeFilePDF: edit.cme_pdf,
                        cmeFileGambar: edit.cme_thumbnail,
                        cmeFileVideo: edit.myfile,
                        cmescore: response.data.score.min_score,
                        cmeProductName1: edit.cme_product_name_1,
                        cmeProductUrl1: edit.cme_product_url_1,
                        cmeProductName2: edit.cme_product_name_2,
                        cmeProductUrl2: edit.cme_product_url_2,
                    })
                }
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    save = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        
        let data = this.state.listCME.filter(a => a.cme_no_idi == this.state.cmeSKPBIDI && a.cme_id !== routerSplitter[5])
        if (data.length > 0) {
            this.setState({
                basicduplicate: true
            })
        } else {
            let formData = new FormData();
            formData.append("myfile", this.state.cmeFilePDF);
            formData.append("myfile2", this.state.cmeFileGambar);
            formData.append("myfile3", this.state.cmeFileVideo);
            formData.append("cme_title", this.state.cmeJudul);
            // formData.append("cme_title", this.state.cmeJudul);
            formData.append("cme_title_search", this.state.cmeJudul);
            formData.append("cme_spesialis", this.state.cmeSpesialis);
            formData.append("cme_spesialis_search", this.state.cmeSpesialis);
            formData.append("cme_no_idi", this.state.cmeSKPBIDI);
            formData.append("cme_content", this.state.cmeDeskripsi);
            formData.append("cme_skp", this.state.cmeSKP);
            formData.append("cme_status", this.state.cmeStatus);
            formData.append("cme_id", this.state.cmeID);
            formData.append("cme_score", this.state.cmescore);
            formData.append("cme_penulis", this.state.cmeOrganisasi);

            // Spesialisasi, Tanggal Mulai & Tanggal Akhir
            formData.append("cme_molekul", this.state.cmeSpesialis1);
            formData.append("cme_penyakit", this.state.cmeSpesialis2);
            formData.append("periode_awal", moment(this.state.tanggal).format('YYYY-MM-DD'));
            formData.append("periode_akhir", moment(this.state.tanggalAkhir).format('YYYY-MM-DD'));

            formData.append("cme_product_name_1", this.state.cmeProductName1);
            formData.append("cme_product_url_1", this.state.cmeProductUrl1);
            formData.append("cme_product_name_2", this.state.cmeProductName2);
            formData.append("cme_product_url_2", this.state.cmeProductUrl2);

            // console.log("formData => ", formData);

            this.setState({
                prosess: true
            })
            httprequester.postupload('cme/update_header', formData, {
                success: async function (response) {
                    if (response.data.message == "success") {
                        this.setState({
                            basic: true,
                            prosess: false
                        })
                        this.fileUploads.current
                    }
                }.bind(this),
                error: function (err) {

                }.bind(this)

            })
        }
      
    }

    backduplicate = () => {
        this.setState({
            basicduplicate: false
        })
    }

    handleChangeSpesialis(e) {
        this.setState({
            cmeSpesialis: e
        })
        // console.log("INIT S", e);
    }

    handleChangeSpesialis1(e) {
        this.setState({
            cmeSpesialis1: e
        })
        // console.log("INIT S", e);
    }

    handleChangeSpesialis2(e) {
        this.setState({
            cmeSpesialis2: e
        })
        // console.log("INIT S", e);
    }

    handleChangeOrganisasi(e) {
        this.setState({
            cmeOrganisasi: e
        })
        // console.log("INIT S", e);
    }

    handleUploadPDF = (e) => {
        this.setState({
            cmeFilePDF: e.target.files[0]
        })
    }

    handleUploadGambar = (e) => {
        this.setState({
            cmeFileGambar: e.target.files[0]
        })
    }

    handleUploadVideo = (e) => {
        this.setState({
            cmeFileVideo: e.target.files[0]
        })
    }

    render() {
        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionOrganisasi = this.state.listOrganisasi.map(k => k.org_name)
        // console.log('option',option);
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Edit CME Online</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="CME Online" breadcrumbItem="Edit CME Online" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="CME Online berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.basicduplicate ? (
                            <SweetAlert
                                danger
                                title="No SK PD IDI Sudah Terdaftar"
                                onConfirm={() => this.backduplicate()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        <AvForm>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Form>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Judul CME
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeJudul"
                                                            type="input"
                                                            placeholder="Input Judul CME"
                                                            onChange={(e) => this.setState({ cmeJudul: e.target.value })}
                                                            value={this.state.cmeJudul}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Minimum Score Kelulusan
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmescore"
                                                            type="input"
                                                            placeholder="Input CME Score"
                                                            onChange={(e) => this.setState({ cmescore: e.target.value })}
                                                            value={this.state.cmescore}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-email-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        SKP
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeSKP"
                                                            type="input"
                                                            placeholder="Input SKP"
                                                            onChange={(e) => this.setState({ cmeSKP: e.target.value })}
                                                            value={this.state.cmeSKP}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-password-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Deskripsi
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeDeskripsi"
                                                            type="textarea"
                                                            rows="3"
                                                            placeholder="Input Deskripsi"
                                                            onChange={(e) => this.setState({ cmeDeskripsi: e.target.value })}
                                                            value={this.state.cmeDeskripsi}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        No. SK PB IDI
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeSKPBIDI"
                                                            type="input"
                                                            placeholder="Input No. SK PB IDI"
                                                            onChange={(e) => this.setState({ cmeSKPBIDI: e.target.value })}
                                                            value={this.state.cmeSKPBIDI}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Organisasi
                                                    </Label>
                                                    <Col sm={6}>
                                                        {(!this.state.cmeOrg) ?
                                                            <AvField
                                                                name="cmeOrganisasi"
                                                                disabled
                                                                type="text"
                                                                value={this.state.cmeOrganisasi} required />
                                                            : <Typeahead
                                                                // allowNew
                                                                id="cmeOrganisasi"
                                                                // multiple
                                                                placeholder="Pilih Organisasi"
                                                                labelKey="name"
                                                                options={optionOrganisasi}
                                                                onChange={(e) => this.handleChangeOrganisasi(e)}
                                                            // selected={this.state.cmeSpesialis}
                                                            />

                                                        }
                                                        {/* <Typeahead
                                                        // allowNew
                                                        id="cmeSpesialis"
                                                        placeholder="Pilih Spesialis"
                                                        labelKey="name"
                                                        options={option}
                                                        onChange={(e) => this.handleChangeSpesialis(e)}
                                                        selected={this.state.cmeSpesialis}
                                                    /> */}
                                                    </Col>
                                                    <Col>
                                                        <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ cmeOrg: !this.state.cmeOrg })} >ubah</a>
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Spesialis
                                                    </Label>
                                                    <Col sm={6}>
                                                        {(!this.state.cmeSpc) ?
                                                            <AvField
                                                                name="cmeSpesialis"
                                                                disabled
                                                                type="text"
                                                                value={this.state.cmeSpesialis} required />
                                                            : <Typeahead
                                                                // allowNew
                                                                id="cmeSpesialis"
                                                                // multiple
                                                                placeholder="Pilih Spesialis"
                                                                labelKey="name"
                                                                options={option}
                                                                onChange={(e) => this.handleChangeSpesialis(e)}
                                                                // selected={this.state.cmeSpesialis}
                                                            />

                                                        }
                                                     
                                                    </Col>
                                                    <Col>
                                                        <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ cmeSpc: !this.state.cmeSpc })} >ubah</a>
                                                    </Col>
                                                </div>

                                                {/* Spesialisasi, Tanggal Mulai & Tanggal Akhir */}
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Spesialis Alternatif 1
                                                    </Label>
                                                    <Col sm={6}>
                                                        {(!this.state.cmeSpc1) ?
                                                            <AvField
                                                                name="cmeSpesialis"
                                                                disabled
                                                                type="text"
                                                                value={this.state.cmeSpesialis1} required />
                                                            : <Typeahead
                                                                // allowNew
                                                                id="cmeSpesialis"
                                                                // multiple
                                                                placeholder="Pilih Spesialis"
                                                                labelKey="name"
                                                                options={option}
                                                                onChange={(e) => this.handleChangeSpesialis1(e)}
                                                            // selected={this.state.cmeSpesialis}
                                                            />

                                                        }
                                                     
                                                    </Col>
                                                    <Col>
                                                        <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ cmeSpc1: !this.state.cmeSpc1 })} >ubah</a>
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Spesialis Alternatif 2
                                                    </Label>
                                                    <Col sm={6}>
                                                        {(!this.state.cmeSpc2) ?
                                                            <AvField
                                                                name="cmeSpesialis"
                                                                disabled
                                                                type="text"
                                                                value={this.state.cmeSpesialis2} required />
                                                            : <Typeahead
                                                                // allowNew
                                                                id="cmeSpesialis"
                                                                // multiple
                                                                placeholder="Pilih Spesialis"
                                                                labelKey="name"
                                                                options={option}
                                                                onChange={(e) => this.handleChangeSpesialis2(e)}
                                                            // selected={this.state.cmeSpesialis}
                                                            />

                                                        }
                                                       
                                                    </Col>
                                                    <Col>
                                                        <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ cmeSpc2: !this.state.cmeSpc2 })} >ubah</a>
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Tanggal Mulai
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            name="tanggal"
                                                            label="Tanggal Mulai"
                                                            type="date"
                                                            // min={moment().format('YYYY-MM-DD')}
                                                            onChange={(e) => this.setState({ tanggal: e.target.value })}
                                                            value={this.state.tanggal}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Tanggal Akhir
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            name="tanggal"
                                                            label="Tanggal Akhir"
                                                            type="date"
                                                            min={moment(this.state.tanggal).format('YYYY-MM-DD')}
                                                            onChange={(e) => this.setState({ tanggalAkhir: e.target.value })}
                                                            value={this.state.tanggalAkhir}
                                                        />
                                                    </Col>
                                                </div>

                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Nama Produk Rekomendasi 1
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeProductName1"
                                                            type="input"
                                                            onChange={(e) => this.setState({ cmeProductName1: e.target.value })}
                                                            value={this.state.cmeProductName1}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        URL Produk Rekomendasi 1
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeProductUrl1"
                                                            type="input"
                                                            onChange={(e) => this.setState({ cmeProductUrl1: e.target.value })}
                                                            value={this.state.cmeProductUrl1}
                                                        />
                                                    </Col>
                                                </div>

                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Nama Produk Rekomendasi 2
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeProductName2"
                                                            type="input"
                                                            onChange={(e) => this.setState({ cmeProductName2: e.target.value })}
                                                            value={this.state.cmeProductName2}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        URL Produk Rekomendasi 2
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeProductUrl2"
                                                            type="input"
                                                            onChange={(e) => this.setState({ cmeProductUrl2: e.target.value })}
                                                            value={this.state.cmeProductUrl2}
                                                        />
                                                    </Col>
                                                </div>

                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        PDF
                                                    </Label>
                                                    <Col sm={9}>
                                                        {
                                                            (this.state.cmeFilePDF) ?
                                                                <a rel="noreferrer" className="btn btn-primary" target={"_blank"} href={this.state.cmeFilePDF}>Lihat File PDF</a>
                                                                : <p>PDF Tidak Tersedia</p>
                                                        }
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Upload PDF <br />(Silakan Upload PDF Baru Bila Ada Perubahan)
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeFilePDF"
                                                            name="file"
                                                            type="file"
                                                            accept=".pdf"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadPDF}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Gambar
                                                    </Label>
                                                    <Col sm={9}>
                                                        <img src={this.state.cmeFileGambar} style={{ width: '20%' }} />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Upload Gambar <br /> (Silakan Upload Gambar Baru Bila Ada Perubahan)
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeFileGambar"
                                                            name="file"
                                                            type="file"
                                                            accept=".png,.jpg,.jpeg"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadGambar}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Video
                                                    </Label>
                                                    <Col sm={9}>
                                                        {
                                                            (this.state.cmeFileVideo) ?
                                                                <a rel="noreferrer" className="btn btn-primary" target={"_blank"} href={this.state.cmeFileVideo}>Lihat Video</a>
                                                                : <p>Video Tidak Tersedia</p>
                                                        }
                                                    </Col>
                                                </div>
                                                <div className="row mb-4">
                                                    <Label
                                                        htmlFor="horizontal-firstname-Input"
                                                        className="col-sm-3 col-form-label"
                                                    >
                                                        Upload Video <br />(Silakan Gunakan Upload Video Bila Ada Perubahan)
                                                    </Label>
                                                    <Col sm={9}>
                                                        <Input
                                                            id="cmeFileVideo"
                                                            name="file"
                                                            type="file"
                                                            accept=".mp4,.wmv,.mov"
                                                            ref={this.fileUploads}
                                                            onChange={this.handleUploadVideo}
                                                        />
                                                    </Col>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </AvForm>

                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" color="primary" className="btn btn-primary w-md" onClick={this.save}>
                                        Submit Edit CME Online
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default EditCME;
