import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody, Form, Input, Label, Button } from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import httprequester from "../../lib/httprequester"

const PerixaEditUser = () => {
  const history = useHistory()
  const location = useLocation()
  const [currentUser, setCurrentUser] = useState(null)
  
  const { id, user } = location.state || {}

  // State for form fields
  const [formData, setFormData] = useState({
    nama_lengkap: "",
    spesialis: "",
    nik: "",
    email: "",
    nomor_telepon: "",
    kota: "",
    institusi: "",
    qr_identifier: "",
    qr_link: "",
    is_from_gform: 0,
    consent: 0,
  })

  useEffect(() => {
    if (user) {
      setFormData(user)
    }
  }, [user])

  useEffect(() => {
    const authUser = localStorage.getItem("user")
    if (authUser) {
      setCurrentUser(JSON.parse(authUser))
    }
  }, [])

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  // Handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: checked ? 1 : 0
    }))
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    
    const updateData = {
      id,
      nama_lengkap: formData.nama_lengkap,
      email: formData.email,
      phone: formData.nomor_telepon,
      spesialis: formData.spesialis,
      nik: formData.nik,
      kota: formData.kota,
      institusi: formData.institusi,
      qr_identifier: formData.qr_identifier,
      qr_link: formData.qr_link,
      is_from_gform: formData.is_from_gform,
      consent: formData.consent,
      updated_by: currentUser.admin_name,
    }

    httprequester.post("admin/update-perixa-user", updateData, {
      success: (response) => {
        if (response.data.message === "success") {
          history.push("/perixa")
        }
      },
      error: (error) => {
        console.error("Error updating user:", error)
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Edit Perixa User</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Perixa" breadcrumbItem="Edit User" />
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col md="10" />
                <Col md="2">
                  <Link to="/perixa">
                    <Button color="primary" className="btn-sm">
                      Back
                    </Button>
                  </Link>
                </Col>
              </Row>

              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="nama_lengkap">Full Name</Label>
                      <Input
                        type="text"
                        id="nama_lengkap"
                        name="nama_lengkap"
                        value={formData.nama_lengkap}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="spesialis">Specialization</Label>
                      <Input
                        type="text"
                        id="spesialis"
                        name="spesialis"
                        value={formData.spesialis}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="nik">NIK</Label>
                      <Input
                        type="text"
                        id="nik"
                        name="nik"
                        value={formData.nik}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="nomor_telepon">Phone Number</Label>
                      <Input
                        type="text"
                        id="nomor_telepon"
                        name="nomor_telepon"
                        value={formData.nomor_telepon}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="kota">City</Label>
                      <Input
                        type="text"
                        id="kota"
                        name="kota"
                        value={formData.kota}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="institusi">Institution</Label>
                      <Input
                        type="text"
                        id="institusi"
                        name="institusi"
                        value={formData.institusi}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="qr_identifier">QR Identifier</Label>
                      <Input
                        type="text"
                        id="qr_identifier"
                        name="qr_identifier"
                        value={formData.qr_identifier}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="qr_link">QR Link</Label>
                      <Input
                        type="text"
                        id="qr_link"
                        name="qr_link"
                        value={formData.qr_link}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="mb-3">
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="is_from_gform"
                          name="is_from_gform"
                          checked={formData.is_from_gform === 1}
                          onChange={handleCheckboxChange}
                        />
                        <Label className="form-check-label" htmlFor="is_from_gform">
                          From Google Form
                        </Label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="consent"
                          name="consent"
                          checked={formData.consent === 1}
                          onChange={handleCheckboxChange}
                        />
                        <Label className="form-check-label" htmlFor="consent">
                          Consent
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="text-end mt-4">
                  <Button type="submit" color="primary">
                    Update User
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PerixaEditUser
