import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardGroup,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead, withItem } from 'react-bootstrap-typeahead';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactHtmlParser from 'react-html-parser';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { CSVLink, CSVDownload } from "react-csv";

import { Editor } from '@tinymce/tinymce-react';
import Kategori from "pages/KategoriTags/kategori";
import moment from "moment";

class QnaPercakapan extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
        this.state = {
            basic: false,
            ListDetailPercakapan: [],
            ListQnaPilihan: [],
            tanya_topik: '',
            tanya_content_id: '',
            tanya_narasumber: '',
            tanya_type: [],
            tanya_penyakit: '',
            tanya_spesialisai: '',
            tanya_summary: '',
            tanya_thumbnail: '',
            tanya_kuota: '',
            tanya_periode_start: '',
            tanya_periode_end: '',
            periode_id: 0,
            quota: 0,
            message: '',
            basicPilih: false,
            tanya_summary_edit: '',
            id: 0,
            conv_id: 0,
            conv_status: 0,
            role: '',
            prosess: false,
            formEditQuota: false,
            formmodalstatus: false,
            dataCSV: [],
            ListDetailQNA: []
        };
    }

    componentDidMount() {
        this.getListTanyaAhli()
        this.getDetailPercakapan()
        this.getQNAPilihan()
    }

    getQNAPilihan = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            "tanya_id": routerSplitter[5],
            "user_id": routerSplitter[6],
            "limit": 10000,
            "page": 1
        }

        httprequester.postarticle('ahli/list-qna', body, {
            success: async function (response) {


                if (response.data.message == "success") {
                    //    console.log("resLOV => ", response.data.detailMessage.data)
                    this.setState({
                        ListDetailQNA: response.data.detailMessage.data
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getDetailPercakapan = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            "tanya_id": routerSplitter[5],
            "user_id": routerSplitter[6],
            "limit": 10000
        }

        httprequester.postarticle('ahli/list-perpeserta', body, {
            success: async function (response) {

                // console.log("resLOV => ", response.data.detailMessage.data[0])
                if (response.data.message == "success") {
                    let data = response.data.detailMessage.data.filter(a => a.tanya_conv_status != '4')
                    this.setState({
                        ListDetailPercakapan: data
                    })

                    let args = [];
                    data.map((v, i) => {
                        let obj = {
                            nama: v.tanya_name,
                            percakapan: v.tanya_conv_text,
                            role: v.tanya_conv_role
                        }
                        args.push(obj)
                    })

                    this.setState({
                        dataCSV: args
                    })

                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }


    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.setState({
            basicPilih: false,
            prosess: false,
            formmodalstatus: false
        })
        this.getListTanyaAhli()
        this.getDetailPercakapan()
        this.getQNAPilihan()
        // this.getListTanyaAhliPeriode()
    }

    getListTanyaAhli = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");
        let body = {
            "tanya_id": routerSplitter[5]
        }

        // console.log("body =>", body)
        httprequester.postarticle('ahli/get-detail', body, {
            success: async function (response) {
                let data = []
                // let cek = response.data.result.data
                if (response.data.message == "success") {
                    this.setState({
                        tanya_topik: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][15] : '',
                        tanya_periode_start: response.data.detailMessage.datarows.length > 0 ? moment(response.data.detailMessage.datarows[0][3]).format('DD MMM YYYY') : '',
                        tanya_kuota: response.data.detailMessage.datarows.length > 0 ? response.data.detailMessage.datarows[0][1] : '',
                        tanya_periode_end: response.data.detailMessage.datarows.length > 0 ? moment(response.data.detailMessage.datarows[0][11]).format('DD MMM YYYY') : ''
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }


    directDetail = (row) => {
        this.props.history.push(`/tanya-ahli/list-percakapan/detail/${row.periode_id}`)

    }

    openModalStatus = (data) => {
        // console.log('data',data);

        let body = {
            "periode_id": data.periode_id,
            "user_id": data.user_id,
            "conv_text": data.tanya_conv_text,
            "conv_role": data.tanya_conv_role,
            "tanya_id": data.tanya_id,
            "tanya_name": data.tanya_name,
            "reference": data.tanya_conv_role == "peserta" ? data.user_id : data.narasumber_id,
            "created_at": data.created_at
        }

        httprequester.postarticle('ahli/insert-qna', body, {
            success: async function (response) {
                // console.log("res => ", response)
                if (response.data.message == "success") {

                    this.back()
                    this.setState({
                        basicPilih: true,
                        message: response.data.detailMessage
                    })

                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    updateStatus = () => {
        let body = {
            "conv_id": this.state.conv_id,
            "conv_status": this.state.conv_status,
            "flag": "update"
        }
        // console.log("body =>", body)
        httprequester.postarticle('ahli/update-perpeserta', body, {
            success: async function (response) {
                // console.log("res => ", response)
                this.getListTanyaAhli()
                this.getDetailPercakapan()
                this.back()
                if (response.data.message == "success") {
                    this.setState({
                        basic: true,
                        formmodalstatus: false,
                        message: response.data.detailMessage
                    })

                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    openModal = (data) => {
        this.setState({
            prosess: true,
            tanya_summary_edit: data.tanya_conv_text,
            id: data.tanya_conv_id,
        })
    }

    openModalAdmin = (role) => {
        this.setState({
            prosess: true,
            tanya_summary_edit: '',
            tanya_summary: '',
            role: role
        })
    }

   

    hapusQNA = (data) => {
        // console.log('data',data);
        let body = {
            "tanya_conv_id": data.tanya_conv_id,
            "tanya_text": data.tanya_conv_text
        }

        httprequester.postarticle('ahli/hapus-qna', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.back()
                    this.setState({
                        basicPilih: true,
                        message: response.data.detailMessage
                    })

                }
                // console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })
    }

    save = () => {
        let router = window.location.href
        let routerSplitter = router.split("/");

        let body = {
            "tanya_conv_id": this.state.id,
            "tanya_text": this.state.tanya_summary ? this.state.tanya_summary : this.state.tanya_summary_edit
        }

        httprequester.postnobody('ahli/update-qna', body, {
            success: async function (response) {
                if (response.data.message == "success") {
                    this.back()
                    this.setState({
                        basicPilih: true,
                        prosess: false,
                        message: response.data.detailMessage
                    })
                }
                // console.log(response)
            }.bind(this),
            error: function (err) {

            }.bind(this)

        })


    }

    directSEO = () => {
        let url = localStorage.getItem('url')
        let hasil = url.split('/')
        // console.log('hasil',hasil);
        this.props.history.push(`/${hasil[3]}/list-percakapan/${hasil[5]}/${hasil[6]}/${hasil[7]}`)
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            tanya_summary: e.target.getContent()
        })
    }

    render() {
        const { SearchBar } = Search;
        // console.log('this',this.state.ListDetailPercakapan);
        return (
            <React.Fragment>


                <div className="page-content">
                    <MetaTags>
                        <title>QNA Pilihan</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tanya Ahli" breadcrumbItem="QNA Pilihan" />

                        {this.state.basicPilih ? (
                            <SweetAlert
                                success
                                title={this.state.message}
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert confirmBtnText="Simpan"
                                cancelBtnText="Tidak" showCancel onConfirm={() => this.save()} onCancel={() => this.back()} >
                                <Form method="post">
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                        initialValue={this.state.tanya_summary_edit}
                                        content={this.state.tanya_summary}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                'removeformat link',
                                            content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                            line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                        }}
                                        onChange={this.handleEditorChange}
                                    />
                                </Form>
                            </SweetAlert>
                        ) : null}

                        {this.state.formmodalstatus ? (
                            <SweetAlert
                                warning
                                showCancel
                                confirmBtnText="Ya"
                                cancelBtnText="Tidak"
                                confirmBtnBsStyle="danger"
                                title="Apakah Anda Yakin ?"
                                onConfirm={this.updateStatus}
                                onCancel={() => this.back()}
                                focusCancelBtn
                            >
                            </SweetAlert>
                        ) : null}



                        <Row>
                            <Col lg={12}>
                                <button type="button" className="btn btn-outline-danger" onClick={() => { this.directSEO(this.state.tanya_id, "tanya_ahli") }}> Kembali</button>
                                <br />
                                <br />
                                <Card>
                                    <CardBody>
                                        <Form id="form-periode">
                                            <div className="mb-3">
                                                <Label htmlFor="formrow-firstname-Input">Nama Tanya Ahli : {this.state.tanya_topik} </Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Periode : {this.state.tanya_periode_start} - {this.state.tanya_periode_end}</Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Quota : {this.state.tanya_kuota}</Label>
                                                <br />
                                                <Label htmlFor="formrow-firstname-Input">Penanya : {this.state.ListDetailPercakapan.length > 0 ? this.state.ListDetailPercakapan[0].tanya_name : ''}</Label>
                                            </div>
                                        </Form>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="6" md="6">
                                <Card style={{
                                    border: "1px solid black"
                                }}>
                                    <CardHeader
                                        style={{
                                            borderBottom: "1px solid black"
                                        }}>
                                        <h5>Percakapan Peserta & Narasumber</h5>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.ListDetailPercakapan.map((v, key) => {
                                            return <Card key={key}
                                                style={{
                                                    borderBottom: "1px solid black"
                                                }}>
                                                {/* <CardBody>
                                                    <p>{v.tanya_conv_role}</p>
                                                    <p><b>{v.tanya_name}</b></p>
                                                    <p>{ReactHtmlParser(v.tanya_conv_text)}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button
                                                            color="success"
                                                            className="btn-sm"
                                                            onClick={(e) => { this.openModalStatus(v) }}
                                                        >
                                                            Pilih Sebagai QNA Pilihan
                                                        </Button>
                                                    </div>
                                                </CardBody> */}

                                                {this.state.ListDetailPercakapan.filter((a, b) => a.reply_id == v.tanya_conv_id).map((t,m) => {
                                                        return <CardBody key={m}>
                                                            <p style={{textAlign:'right'}}>{t.tanya_conv_role == 'peserta' ? 'Pertanyaan' : 'Jawaban'}</p>
                                                            <p><b>{t.tanya_name}</b></p>
                                                            <p>{ReactHtmlParser(t.tanya_conv_text)}</p>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Button
                                                                    color="success"
                                                                    className="btn-sm"
                                                                    onClick={(e) => { this.openModalStatus(t) }}
                                                                >
                                                                    Pilih Sebagai QNA Pilihan
                                                                </Button>
                                                            </div>
                                                        </CardBody>
                                                })}

                                                {v.reply_id == null ? <CardBody>
                                                    <p style={{ textAlign: 'right' }}>{v.tanya_conv_role == 'peserta' ? 'Pertanyaan' : 'Jawaban'}</p>
                                                    <p><b>{v.tanya_name}</b></p>
                                                    <p>{ReactHtmlParser(v.tanya_conv_text)}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button
                                                            color="success"
                                                            className="btn-sm"
                                                            onClick={(e) => { this.openModalStatus(v) }}
                                                        >
                                                            Pilih Sebagai QNA Pilihan
                                                        </Button>
                                                    </div>
                                                </CardBody> : ''}
                                            </Card>
                                        })}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="6" md="6">
                                <Card style={{
                                    border: "1px solid black"
                                }}>
                                    <CardHeader
                                        style={{
                                            borderBottom: "1px solid black"
                                        }}
                                    >
                                        <h5>QNA Pilihan Peserta & Narasumber</h5>
                                    </CardHeader>
                                    <CardBody>
                                        {this.state.ListDetailQNA.map((v, key) => {
                                            return <Card key={key} style={{
                                                borderBottom: "1px solid black"
                                            }}>
                                                <CardBody>
                                                    <p><b>{v.tanya_name}</b></p>
                                                    <p>{ReactHtmlParser(v.tanya_conv_text)}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Button
                                                            color="success"
                                                            className="btn-sm"
                                                            onClick={(e) => { this.openModal(v) }}
                                                        >
                                                            Ubah
                                                        </Button>
                                                        &nbsp;
                                                        <Button
                                                            color="danger"
                                                            className="btn-sm"
                                                            onClick={(e) => { this.hapusQNA(v) }}
                                                        >
                                                            Hapus
                                                        </Button>
                                                    </div>
                                                </CardBody>
                                            </Card>

                                        })}
                                    </CardBody>
                                </Card>


                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default QnaPercakapan;
