module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  // urlAPI: "http://localhost:3333/api/v1/",
  // urlAPI: "http://192.168.3.117:30631/api/v1/"

  //prod
  // urlAPI: "https://api.ruangilmiah-dexa.com/api/v1/",
  // urlWeb: "https://ruangilmiah-dexa.com/"

  //dev
  urlAPI: "https://api.ruang-ilmiah.com/api/v1/",
  urlWeb: "https://appdev.ruangilmiah-dexa.com/",
 
  //local
  // urlAPI: "http://localhost:3333/api/v1/",
  // urlWeb: "https://localhost:3000/",
 
}
