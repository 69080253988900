import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Card,
    CardBody,
    Form,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Container } from "reactstrap"
import httprequester from '../../lib/httprequester'
import { Typeahead } from 'react-bootstrap-typeahead';

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Editor } from '@tinymce/tinymce-react';
// import { AvForm, AvField } from 'availity-reactstrap-validation';

import moment from "moment";
import { GetIndexFromSchema } from '../../helpers/CommonHelper';

class EditWebinar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            basic: false,
            prosess: false,
            listWebinar: [],
            listCategory: [],
            listSpesialis: [],
            listMolekul: [],
            listPenyakit: [],
            listPenulis: [],
            webinarID: '',
            webinarJudul: '',
            webinarPenulis: '',
            webinarPenyakit: '',
            webinarMolekul: '',
            webinarMolekulNew: '',
            webinarPenyakitNew: '',
            webinarSpesialis: '',
            webinarKonten: '',
            webinarGambar: '',
            webinarVideo: '',
            webinarCreatedby: '',
            status: '',
            chspepe: false,
            chspe: false,
            chspep: false,
            chspem: false,
            name: '',
            tanggalAwal: '',
            tanggalAkhir: '',
            webinarProductName1: '',
            webinarProductUrl1: '',
            webinarProductName2: '',
            webinarProductUrl2: '',
            webinarDefaultImageUrl: '',

            webinarShortJudul1: '',
            webinarShortThumbnail1: '',
            webinarShortUrl1: '',
            webinarShortMenit1: '',

            webinarShortJudul2: '',
            webinarShortThumbnail2: '',
            webinarShortUrl2: '',
            webinarShortMenit2: '',
            
            webinarShortJudul3: '',
            webinarShortThumbnail3: '',
            webinarShortUrl3: '',
            webinarShortMenit3: '',

            webinarShortJudul4: '',
            webinarShortThumbnail4: '',
            webinarShortUrl4: '',
            webinarShortMenit4: '',

            webinarShortJudul5: '',
            webinarShortThumbnail5: '',
            webinarShortUrl5: '',
            webinarShortMenit5: '',

            webinarShortJudul6: '',
            webinarShortThumbnail6: '',
            webinarShortUrl6: '',
            webinarShortMenit6: '',

            webinarShortJudul7: '',
            webinarShortThumbnail7: '',
            webinarShortUrl7: '',
            webinarShortMenit7: '',

            webinarShortJudul8: '',
            webinarShortThumbnail8: '',
            webinarShortUrl8: '',
            webinarShortMenit8: '',

            webinarShortJudul9: '',
            webinarShortThumbnail9: '',
            webinarShortUrl9: '',
            webinarShortMenit9: '',

            webinarShortJudul10: '',
            webinarShortThumbnail10: '',
            webinarShortUrl10: '',
            webinarShortMenit10: '',

            durasiVideoFullDtk: '',
        };
    }

    componentDidMount() {
        let x = JSON.parse(localStorage.getItem('user'))
        if (x) {
            this.setState({
                name: x.admin_name
            })
        }
        // console.log('x',x)
        let router = window.location.href;
        let routerSplitter = router.split("/");
        let id = routerSplitter[5];
        let status = routerSplitter[6];
        let final = id.replace(/\%20/g, ' ');

        this.setState({
            webinarID: final,
            status: status
        });

        this.getDetailWebinar(final);
        this.getCategory();
        this.getLOV();
    }

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    back = () => {
        this.props.history.push(`/webinar`)
    }

    getCategory = () => {
        httprequester.get('category/getall', {
            success: async function (response) {

                console.log("resCat => ", response)
                if (response.data.message == "success") {
                    this.setState({
                        listCategory: response.data.detailMessage
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getLOV = () => {
        httprequester.get('artikel/lov', {
            success: async function (response) {

                console.log("resLOV => ", response)
                if (response.data.message == "success") {
                    let datapenyakit = []
                    response.data.detailPenyakit.forEach((v, key) => {
                        // console.log('v',v);
                        datapenyakit.push({
                            penyakit_id: v.penyakit_id,
                            penyakit_name: v.penyakit_name == null ? '-' : v.penyakit_name
                        })
                    })
                    this.setState({
                        listSpesialis: response.data.detailSpecialist,
                        listMolekul: response.data.detailMolekul,
                        listPenyakit: datapenyakit,
                        listPenulis: response.data.detailPenulis
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    getDetailWebinar = (value) => {
        console.log("idnnya => ", this.state.webinarID)
        let body = {
            "ri_webinar_id": value
        }

        console.log("body =>", body)
        httprequester.postarticle('webinar/detail', body, {
            success: async function (response) {
                let data = []
                let cek = response.data.settings.datarows
                // console.log("res => ", cek)
                if (response.data.message == "success") {
                    cek.forEach(async (v, key) => {
                        await data.push({
                            created_at: v[GetIndexFromSchema('created_at', response.data.settings.schema)],
                            webinar_spesialis: v[GetIndexFromSchema('webinar_spesialis', response.data.settings.schema)],
                            webinar_molekul: v[GetIndexFromSchema('webinar_molekul', response.data.settings.schema)],
                            webinar_thumbnail: v[GetIndexFromSchema('webinar_thumbnail', response.data.settings.schema)],
                            created_by: v[GetIndexFromSchema('created_by', response.data.settings.schema)],
                            webinar_video: v[GetIndexFromSchema('webinar_video', response.data.settings.schema)],
                            webinar_slug: v[GetIndexFromSchema('webinar_slug', response.data.settings.schema)],
                            webinar_penyakit: v[GetIndexFromSchema('webinar_penyakit', response.data.settings.schema)],
                            webinar_id: v[GetIndexFromSchema('webinar_id', response.data.settings.schema)],
                            webinar_content: v[GetIndexFromSchema('webinar_content', response.data.settings.schema)],
                            webinar_title: v[GetIndexFromSchema('webinar_title', response.data.settings.schema)],
                            webinar_penulis: v[GetIndexFromSchema('webinar_penulis', response.data.settings.schema)],
                            webinar_status: v[GetIndexFromSchema('webinar_status', response.data.settings.schema)],
                            webcast_video_duration_s: v[GetIndexFromSchema('webcast_video_duration_s', response.data.settings.schema)],
                        });

                        const dataTanggalAwal = v[GetIndexFromSchema('webinar_periode_awal', response.data.settings.schema)];
                        const dataTanggalAkhir = v[GetIndexFromSchema('webinar_periode_akhir', response.data.settings.schema)];
                        const webinarShort = v[GetIndexFromSchema('webinar_video_parts', response.data.settings.schema)];
                        // console.log('//x//webinarShort: ', webinarShort);
                        this.setState({
                            webinarID: v[GetIndexFromSchema('webinar_id', response.data.settings.schema)],
                            webinarJudul: v[GetIndexFromSchema('webinar_title', response.data.settings.schema)],
                            webinarPenulis: v[GetIndexFromSchema('webinar_penulis', response.data.settings.schema)],
                            webinarPenyakit: v[GetIndexFromSchema('webinar_penyakit', response.data.settings.schema)],
                            webinarMolekul: v[GetIndexFromSchema('webinar_molekul', response.data.settings.schema)],
                            webinarSpesialis: v[GetIndexFromSchema('webinar_spesialis', response.data.settings.schema)],
                            webinarKonten: v[GetIndexFromSchema('webinar_content', response.data.settings.schema)],
                            webinarGambar: v[GetIndexFromSchema('webinar_thumbnail', response.data.settings.schema)],
                            webinarVideo: v[GetIndexFromSchema('webinar_video', response.data.settings.schema)],
                            webinarCreatedby: v[GetIndexFromSchema('created_by', response.data.settings.schema)],
                            tanggalAwal: dataTanggalAwal == null || moment(dataTanggalAwal).format('YYYY-MM-DD') == '2022-01-01' ? '' : moment(dataTanggalAwal).format('YYYY-MM-DD'),
                            tanggalAkhir: dataTanggalAkhir == null || moment(dataTanggalAkhir).format('YYYY-MM-DD') == '4712-12-31' ? '' : moment(dataTanggalAkhir).format('YYYY-MM-DD'),
                            webinarProductName1: v[GetIndexFromSchema('webcast_product_name_1', response.data.settings.schema)],
                            webinarProductUrl1: v[GetIndexFromSchema('webcast_product_url_1', response.data.settings.schema)],
                            webinarProductName2: v[GetIndexFromSchema('webcast_product_name_2', response.data.settings.schema)],
                            webinarProductUrl2: v[GetIndexFromSchema('webcast_product_url_2', response.data.settings.schema)],
                            webinarDefaultImageUrl: v[GetIndexFromSchema('webcast_default_image_url', response.data.settings.schema)],
                            durasiVideoFullDtk: v[GetIndexFromSchema('webcast_video_duration_s', response.data.settings.schema)],
                            
                            webinarShortMenit1: webinarShort && webinarShort[0] ? webinarShort[0]['minute_start_at'] : '',
                            webinarShortThumbnail1: webinarShort && webinarShort[0] ? webinarShort[0]['part_thumbnail'] : '',
                            webinarShortUrl1: webinarShort && webinarShort[0] ? webinarShort[0]['part_url'] : '',
                            webinarShortJudul1: webinarShort && webinarShort[0] ? webinarShort[0]['title'] : '',
                            
                            webinarShortMenit2: webinarShort && webinarShort[1] ? webinarShort[1]['minute_start_at'] : '',
                            webinarShortThumbnail2: webinarShort && webinarShort[1] ? webinarShort[1]['part_thumbnail'] : '',
                            webinarShortUrl2: webinarShort && webinarShort[1] ? webinarShort[1]['part_url'] : '',
                            webinarShortJudul2: webinarShort && webinarShort[1] ? webinarShort[1]['title'] : '',
                            
                            webinarShortMenit3: webinarShort && webinarShort[2] ? webinarShort[2]['minute_start_at'] : '',
                            webinarShortThumbnail3: webinarShort && webinarShort[2] ? webinarShort[2]['part_thumbnail'] : '',
                            webinarShortUrl3: webinarShort && webinarShort[2] ? webinarShort[2]['part_url'] : '',
                            webinarShortJudul3: webinarShort && webinarShort[2] ? webinarShort[2]['title'] : '',
                                                        
                            webinarShortMenit4: webinarShort && webinarShort[3] ? webinarShort[3]['minute_start_at'] : '',
                            webinarShortThumbnail4: webinarShort && webinarShort[3] ? webinarShort[3]['part_thumbnail'] : '',
                            webinarShortUrl4: webinarShort && webinarShort[3] ? webinarShort[3]['part_url'] : '',
                            webinarShortJudul4: webinarShort && webinarShort[3] ? webinarShort[3]['title'] : '',
                            
                            webinarShortMenit5: webinarShort && webinarShort[4] ? webinarShort[4]['minute_start_at'] : '',
                            webinarShortThumbnail5: webinarShort && webinarShort[4] ? webinarShort[4]['part_thumbnail'] : '',
                            webinarShortUrl5: webinarShort && webinarShort[4] ? webinarShort[4]['part_url'] : '',
                            webinarShortJudul5: webinarShort && webinarShort[4] ? webinarShort[4]['title'] : '',
                            
                            webinarShortMenit6: webinarShort && webinarShort[5] ? webinarShort[5]['minute_start_at'] : '',
                            webinarShortThumbnail6: webinarShort && webinarShort[5] ? webinarShort[5]['part_thumbnail'] : '',
                            webinarShortUrl6: webinarShort && webinarShort[5] ? webinarShort[5]['part_url'] : '',
                            webinarShortJudul6: webinarShort && webinarShort[5] ? webinarShort[5]['title'] : '',
                            
                            webinarShortMenit7: webinarShort && webinarShort[6] ? webinarShort[6]['minute_start_at'] : '',
                            webinarShortThumbnail7: webinarShort && webinarShort[6] ? webinarShort[6]['part_thumbnail'] : '',
                            webinarShortUrl7: webinarShort && webinarShort[6] ? webinarShort[6]['part_url'] : '',
                            webinarShortJudul7: webinarShort && webinarShort[6] ? webinarShort[6]['title'] : '',
                            
                            webinarShortMenit8: webinarShort && webinarShort[7] ? webinarShort[7]['minute_start_at'] : '',
                            webinarShortThumbnail8: webinarShort && webinarShort[7] ? webinarShort[7]['part_thumbnail'] : '',
                            webinarShortUrl8: webinarShort && webinarShort[7] ? webinarShort[7]['part_url'] : '',
                            webinarShortJudul8: webinarShort && webinarShort[7] ? webinarShort[7]['title'] : '',
                            
                            webinarShortMenit9: webinarShort && webinarShort[8] ? webinarShort[8]['minute_start_at'] : '',
                            webinarShortThumbnail9: webinarShort && webinarShort[8] ? webinarShort[8]['part_thumbnail'] : '',
                            webinarShortUrl9: webinarShort && webinarShort[8] ? webinarShort[8]['part_url'] : '',
                            webinarShortJudul9: webinarShort && webinarShort[8] ? webinarShort[8]['title'] : '',
                            
                            webinarShortMenit10: webinarShort && webinarShort[9] ? webinarShort[9]['minute_start_at'] : '',
                            webinarShortThumbnail10: webinarShort && webinarShort[9] ? webinarShort[9]['part_thumbnail'] : '',
                            webinarShortUrl10: webinarShort && webinarShort[9] ? webinarShort[9]['part_url'] : '',
                            webinarShortJudul10: webinarShort && webinarShort[9] ? webinarShort[9]['title'] : '',
                        });
                    })
                    this.setState({
                        listWebinar: data
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    saveWebinar = () => {
        let slugLower = this.state.webinarJudul.toLowerCase();
        let slugRep = slugLower.replace(/\s/g, '-');
        // console.log('as', this.state.webinarSpesialis);

        let body = {
            "webinar_id": this.state.webinarID,
            "webinar_penyakit": this.state.webinarPenyakitNew ? this.state.webinarPenyakitNew.toString() : this.state.webinarPenyakit,
            "webinar_penyakit_search": this.state.webinarPenyakitNew ? this.state.webinarPenyakitNew.toString() : this.state.webinarPenyakit,
            "webinar_molekul": this.state.webinarMolekulNew ? this.state.webinarMolekulNew.toString() : this.state.webinarMolekul,
            "webinar_molekul_search": this.state.webinarMolekulNew ? this.state.webinarMolekulNew.toString() : this.state.webinarMolekul,
            "webinar_spesialis": (this.state.webinarSpesialis !== null) ? this.state.webinarSpesialis.toString() : this.state.webinarSpesialis,
            "webinar_spesialis_search": (this.state.webinarSpesialis !== null) ? this.state.webinarSpesialis.toString() : this.state.webinarSpesialis,
            "webinar_title": this.state.webinarJudul,
            "webinar_title_search": this.state.webinarJudul,
            // "webinar_slug": slugRep,
            "webinar_content": this.state.webinarKonten,
            "webinar_thumbnail": this.state.webinarGambar,
            "webinar_video": this.state.webinarVideo,
            "webinar_status": "Unpublished",
            "webinar_penulis": this.state.webinarPenulis.toString(),
            "webinar_penulis_search": this.state.webinarPenulis.toString(),
            "created_by": this.state.name,
            "webinar_periode_awal" : this.state.tanggalAwal === "" ? '2022-01-01' : moment(this.state.tanggalAwal).format('YYYY-MM-DD'),
            "webinar_periode_akhir" : this.state.tanggalAkhir === "" ? '4712-12-31' : moment(this.state.tanggalAkhir).format('YYYY-MM-DD'),
            "webcast_product_name_1": this.state.webinarProductName1,
            "webcast_product_url_1": this.state.webinarProductUrl1,
            "webcast_product_name_2": this.state.webinarProductName2,
            "webcast_product_url_2": this.state.webinarProductUrl2,
            "webcast_default_image_url": this.state.webinarDefaultImageUrl,
            "webinar_video_parts": [
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit1),
                    "part_thumbnail": this.state.webinarShortThumbnail1,
                    "part_url": this.state.webinarShortUrl1,
                    "title": this.state.webinarShortJudul1,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit2),
                    "part_thumbnail": this.state.webinarShortThumbnail2,
                    "part_url": this.state.webinarShortUrl2,
                    "title": this.state.webinarShortJudul2,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit3),
                    "part_thumbnail": this.state.webinarShortThumbnail3,
                    "part_url": this.state.webinarShortUrl3,
                    "title": this.state.webinarShortJudul3,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit4),
                    "part_thumbnail": this.state.webinarShortThumbnail4,
                    "part_url": this.state.webinarShortUrl4,
                    "title": this.state.webinarShortJudul4,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit5),
                    "part_thumbnail": this.state.webinarShortThumbnail5,
                    "part_url": this.state.webinarShortUrl5,
                    "title": this.state.webinarShortJudul5,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit6),
                    "part_thumbnail": this.state.webinarShortThumbnail6,
                    "part_url": this.state.webinarShortUrl6,
                    "title": this.state.webinarShortJudul6,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit7),
                    "part_thumbnail": this.state.webinarShortThumbnail7,
                    "part_url": this.state.webinarShortUrl7,
                    "title": this.state.webinarShortJudul7,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit8),
                    "part_thumbnail": this.state.webinarShortThumbnail8,
                    "part_url": this.state.webinarShortUrl8,
                    "title": this.state.webinarShortJudul8,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit9),
                    "part_thumbnail": this.state.webinarShortThumbnail9,
                    "part_url": this.state.webinarShortUrl9,
                    "title": this.state.webinarShortJudul9,
                },
                {
                    "minute_start_at": parseInt(this.state.webinarShortMenit10),
                    "part_thumbnail": this.state.webinarShortThumbnail10,
                    "part_url": this.state.webinarShortUrl10,
                    "title": this.state.webinarShortJudul10,
                },
            ],
            "webcast_video_duration_s": parseInt(this.state.durasiVideoFullDtk),
        }

        console.log("body =>", body)
        this.setState({
            prosess: true
        })
        httprequester.postarticle('webinar/update', body, {
            success: async function (response) {
                console.log("res => ", response)
                if (response.data.message == "success") {
                    httprequester.postarticle('homepage/lov_destruct', {content_id: this.state.webinarID}, {
                        success: async function (responses) {
                            console.log(responses)
                        }.bind(this),
                        error: function (err) {
                            console.log("error", err)
                        }.bind(this)
                    })
                    this.setState({
                        basic: true,
                        prosess: false
                    })
                }
            }.bind(this),
            error: function (err) {
                console.log("error", err)
            }.bind(this)

        })
    }

    handleChangeJudul(e) {
        // let data = []
        // data.push({
        //     webinarJudul: e[0].webinarJudul
        // })

        this.setState({
            listWebinar: { webinarJudul: e[0].webinarJudul }
            // webinarMolekul: listWebinar.webinarJudul
        })
        console.log("INIT M", e);
    }

    handleChangePenulis(e) {
        this.setState({
            webinarPenulis: e
        })
        console.log("INIT N", e);
    }

    handleChangeSpesialis(e) {
        this.setState({
            webinarSpesialis: e
        })
        console.log("INIT S", e);
    }

    handleChangeMolekul(e) {
        this.setState({
            webinarMolekulNew: e
        })
        console.log("INIT M", e);
    }

    handleChangePenyakit(e) {
        // let data = []
        // data.push({
        //     webinarPenyakitNew: e
        // })

        this.setState({
            webinarPenyakitNew: e
        })
        console.log("INIT P", e);
    }

    handleEditorChange = (e) => {
        console.log(
            'Content was updated:',
            e.target.getContent()
        );
        this.setState({
            webinarKonten: e.target.getContent()
        })
    }

    render() {
        const option = this.state.listSpesialis.map(o => o.specialist_name)
        const optionMolekul = this.state.listMolekul.map(o => o.molekul_name)
        const optionPenyakit = this.state.listPenyakit.map(o => o.penyakit_name)
        const optionPenulis = this.state.listPenulis.map(o => o.penulis_name)
        // console.log("judul => ", this.state.listWebinar[0])
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Edit Webinar</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Webinar" breadcrumbItem="Edit Webinar" />

                        {this.state.basic ? (
                            <SweetAlert
                                success
                                title="Webinar berhasil di simpan"
                                onConfirm={() => this.back()}
                            ></SweetAlert>
                        ) : null}

                        {this.state.prosess ? (
                            <SweetAlert title="proses simpan ..." showConfirm={false} >
                            </SweetAlert>
                        ) : null}

                        {this.state.listWebinar.map(function (webinar, index) {
                            return <Col key={index}>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Form>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Judul Webinar</Label>
                                                        <Input
                                                            id="webinarJudul"
                                                            type="input"
                                                            placeholder={webinar.webinar_title}
                                                            // onChange={(e) => this.handleChangeJudul(e)}
                                                            onChange={(e) => this.setState({ webinarJudul: e.target.value })}
                                                            value={this.state.webinarJudul}
                                                        />
                                                    </div>

                                                    <Row>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Label for="exampleSpesialisasi">
                                                                        Penulis
                                                                    </Label>
                                                                    <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspepe: !this.state.chspepe })} >ubah</a>
                                                                </div>
                                                                {/* <Label htmlFor="formrow-firstname-Input">Penulis</Label> */}
                                                                {(!this.state.chspepe) ?
                                                                    <Input
                                                                        name="judul"
                                                                        disabled
                                                                        type="text"
                                                                        value={this.state.webinarPenulis} />
                                                                    : <Typeahead
                                                                        // allowNew
                                                                        id="basic-typeahead-multiple"
                                                                        multiple
                                                                        placeholder="Pilih Penulis"
                                                                        labelKey="name"
                                                                        options={optionPenulis}
                                                                        onChange={(e) => this.handleChangePenulis(e)}
                                                                        value={this.state.webinarPenulis}
                                                                    />
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-email-Input">URL Gambar Thumbnail</Label>
                                                                <Input
                                                                    id="animasiThumbnail"
                                                                    type="input"
                                                                    placeholder={webinar.webinar_thumbnail}
                                                                    onChange={(e) => this.setState({ webinarGambar: e.target.value })}
                                                                    value={this.state.webinarGambar}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-email-Input">URL Video</Label>
                                                                <Input
                                                                    id="webinarVideo"
                                                                    type="input"
                                                                    placeholder={webinar.webinar_video}
                                                                    onChange={(e) => this.setState({ webinarVideo: e.target.value })}
                                                                    value={this.state.webinarVideo}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video (detik)</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ durasiVideoFullDtk: e.target.value })}
                                                                    value={this.state.durasiVideoFullDtk}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Label for="exampleSpesialisasi">
                                                                        Spesialisasi
                                                                    </Label>
                                                                    <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspe: !this.state.chspe })} >ubah</a>
                                                                </div>
                                                                {/* <Label htmlFor="formrow-InputCity">Spesialis</Label> */}
                                                                {(!this.state.chspe) ?
                                                                    <Input
                                                                        name="judul"
                                                                        disabled
                                                                        type="text"
                                                                        value={this.state.webinarSpesialis} />
                                                                    : <Typeahead
                                                                        id="basic-typeahead-multiple"
                                                                        multiple
                                                                        placeholder="Pilih Spesialis"
                                                                        labelKey="name"
                                                                        options={option}
                                                                        onChange={(e) => this.handleChangeSpesialis(e)}
                                                                        value={this.state.webinarSpesialis}
                                                                    />
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Label for="exampleSpesialisasi">
                                                                        Molekul
                                                                    </Label>
                                                                    <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspem: !this.state.chspem })} >ubah</a>
                                                                </div>
                                                                {/* <Label htmlFor="formrow-InputState">Molekul</Label> */}
                                                                {(!this.state.chspem) ?
                                                                    <Input
                                                                        name="judul"
                                                                        disabled
                                                                        type="text"
                                                                        value={this.state.webinarMolekul} />
                                                                    : <Typeahead
                                                                        id="basic-typeahead-multiple"
                                                                        multiple
                                                                        placeholder="Pilih Molekul"
                                                                        labelKey="name"
                                                                        options={optionMolekul}
                                                                        onChange={(e) => this.handleChangeMolekul(e)}
                                                                        value={this.state.webinarMolekulNew}
                                                                    />
                                                                }

                                                            </div>
                                                        </Col>

                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Label for="exampleSpesialisasi">
                                                                        Penyakit
                                                                    </Label>
                                                                    <a className="font-ri-color" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ chspep: !this.state.chspep })} >ubah</a>
                                                                </div>
                                                                {/* <Label htmlFor="formrow-InputZip">Penyakit</Label> */}
                                                                {(!this.state.chspep) ?
                                                                    <Input
                                                                        name="judul"
                                                                        disabled
                                                                        type="text"
                                                                        value={this.state.webinarPenyakit} />
                                                                    : <Typeahead
                                                                        id="basic-typeahead-multiple"
                                                                        multiple
                                                                        placeholder="Pilih Penyakit"
                                                                        labelKey="name"
                                                                        options={optionPenyakit}
                                                                        onChange={(e) => this.handleChangePenyakit(e)}
                                                                        value={this.state.webinarPenyakitNew}
                                                                    />
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label>Tanggal Mulai</Label>
                                                                <Input
                                                                    name="tanggal"
                                                                    label="Tanggal Mulai"
                                                                    type="date"
                                                                    onChange={(e) => this.setState({ tanggalAwal: e.target.value })}
                                                                    value={this.state.tanggalAwal}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label>Tanggal Akhir</Label>
                                                                <Input
                                                                    name="tanggal"
                                                                    label="Tanggal Akhir"
                                                                    type="date"
                                                                    min={moment(this.state.tanggalAwal).format('YYYY-MM-DD')}
                                                                    onChange={(e) => this.setState({ tanggalAkhir: e.target.value })}
                                                                    value={this.state.tanggalAkhir}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>                            

                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <Label>Nama Produk Rekomendasi 1</Label>
                                                                <Input
                                                                    id="webinarProductName1"
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarProductName1: e.target.value })}
                                                                    value={this.state.webinarProductName1}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={8}>
                                                            <div className="mb-3">
                                                                <Label>URL Produk Rekomendasi 1</Label>
                                                                <Input
                                                                    id="webinarProductUrl1"
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarProductUrl1: e.target.value })}
                                                                    value={this.state.webinarProductUrl1}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="mb-3">
                                                                <Label>Nama Produk Rekomendasi 2</Label>
                                                                <Input
                                                                    id="webinarProductName2"
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarProductName2: e.target.value })}
                                                                    value={this.state.webinarProductName2}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={8}>
                                                            <div className="mb-3">
                                                                <Label>URL Produk Rekomendasi 2</Label>
                                                                <Input
                                                                    id="webinarProductUrl2"
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarProductUrl2: e.target.value })}
                                                                    value={this.state.webinarProductUrl2}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label>Default Image URL</Label>
                                                                <Input
                                                                    id="webinarDefaultImageUrl"
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarDefaultImageUrl: e.target.value })}
                                                                    value={this.state.webinarDefaultImageUrl}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 1</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul1: e.target.value })}
                                                                    value={this.state.webinarShortJudul1}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 1</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail1: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail1}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 1</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl1: e.target.value })}
                                                                    value={this.state.webinarShortUrl1}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 1</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit1: e.target.value })}
                                                                    value={this.state.webinarShortMenit1}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 2</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul2: e.target.value })}
                                                                    value={this.state.webinarShortJudul2}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 2</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail2: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail2}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 2</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl2: e.target.value })}
                                                                    value={this.state.webinarShortUrl2}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 2</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit2: e.target.value })}
                                                                    value={this.state.webinarShortMenit2}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 3</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul3: e.target.value })}
                                                                    value={this.state.webinarShortJudul3}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 3</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail3: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail3}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 3</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl3: e.target.value })}
                                                                    value={this.state.webinarShortUrl3}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 3</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit3: e.target.value })}
                                                                    value={this.state.webinarShortMenit3}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 4</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul4: e.target.value })}
                                                                    value={this.state.webinarShortJudul4}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 4</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail4: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail4}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 4</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl4: e.target.value })}
                                                                    value={this.state.webinarShortUrl4}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 4</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit4: e.target.value })}
                                                                    value={this.state.webinarShortMenit4}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 5</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul5: e.target.value })}
                                                                    value={this.state.webinarShortJudul5}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 5</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail5: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail5}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 5</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl5: e.target.value })}
                                                                    value={this.state.webinarShortUrl5}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 5</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit5: e.target.value })}
                                                                    value={this.state.webinarShortMenit5}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 6</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul6: e.target.value })}
                                                                    value={this.state.webinarShortJudul6}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 6</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail6: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail6}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 6</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl6: e.target.value })}
                                                                    value={this.state.webinarShortUrl6}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 6</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit6: e.target.value })}
                                                                    value={this.state.webinarShortMenit6}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 7</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul7: e.target.value })}
                                                                    value={this.state.webinarShortJudul7}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 7</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail7: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail7}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 7</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl7: e.target.value })}
                                                                    value={this.state.webinarShortUrl7}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 7</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit7: e.target.value })}
                                                                    value={this.state.webinarShortMenit7}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 8</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul8: e.target.value })}
                                                                    value={this.state.webinarShortJudul8}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 8</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail8: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail8}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 8</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl8: e.target.value })}
                                                                    value={this.state.webinarShortUrl8}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 8</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit8: e.target.value })}
                                                                    value={this.state.webinarShortMenit8}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 9</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul9: e.target.value })}
                                                                    value={this.state.webinarShortJudul9}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 9</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail9: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail9}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 9</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl9: e.target.value })}
                                                                    value={this.state.webinarShortUrl9}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 9</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit9: e.target.value })}
                                                                    value={this.state.webinarShortMenit9}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className="mb-4">
                                                                <Label htmlFor="formrow-firstname-Input">Judul Webinar Short 10</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortJudul10: e.target.value })}
                                                                    value={this.state.webinarShortJudul10}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Gambar Thumbnail 10</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortThumbnail10: e.target.value })}
                                                                    value={this.state.webinarShortThumbnail10}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-firstname-Input">URL Video 10</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortUrl10: e.target.value })}
                                                                    value={this.state.webinarShortUrl10}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="mb-2">
                                                                <Label htmlFor="formrow-firstname-Input">Durasi Video 10</Label>
                                                                <Input
                                                                    type="input"
                                                                    onChange={(e) => this.setState({ webinarShortMenit10: e.target.value })}
                                                                    value={this.state.webinarShortMenit10}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 10 }}>
                                    <Col xs="12" md="12">
                                        <Card>
                                            <CardBody>
                                                <Form method="post">
                                                    <Editor
                                                        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                                        initialValue={webinar.webinar_content}
                                                        init={{
                                                            height: 500,
                                                            menubar: false,
                                                            plugins: [
                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar: 'undo redo | formatselect | ' +
                                                                'bold italic forecolor backcolor | alignleft aligncenter ' +
                                                                'alignright alignjustify | bullist numlist outdent indent lineheight | ' +
                                                                'removeformat link',
                                                            content_style: 'body { font-family:Poppins,Arial,sans-serif; }',
                                                            line_height_formats: '0.2 0.4 0.6 0.8 1 1.2 1.4 1.6 2',
                                                        }}
                                                        onChange={this.handleEditorChange}
                                                    />
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        }.bind(this))}
                        <Row style={{ paddingTop: 10 }}>
                            <Col xs="12" md="12">
                                <Card>
                                    <Button type="submit" className="btn btn-primary w-md" onClick={() => this.saveWebinar()}>
                                        Submit Webinar
                                    </Button>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row md="10" style={{ paddingTop: 8, justifyContent: "end", alignItems: "end" }}>
                            <Button color="danger" onClick={() => this.saveWebinar()}>
                                Submit Artikel
                            </Button>
                        </Row> */}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

export default EditWebinar;
